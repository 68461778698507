var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "invite-sidebar a" }, [
    _c(
      "div",
      { staticClass: "invite-box" },
      [
        _c(
          "el-dialog",
          {
            staticClass: "invite-box-dialog",
            attrs: { title: "", visible: _vm.dialogVisible, width: "495px" },
            on: {
              "update:visible": function ($event) {
                _vm.dialogVisible = $event
              },
            },
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                staticClass: "invite-content",
              },
              [
                _c("i", {
                  staticClass: "invite-close",
                  attrs: { title: "关闭" },
                  on: { click: _vm.hide },
                }),
                _c("div", { staticClass: "invite-secret clearfix" }, [
                  _c("div", { staticClass: "invite-intro" }, [
                    _vm._v("扫码后转发到微信好友 / 微信群"),
                  ]),
                  _c("div", { staticClass: "invite-qrcode" }, [
                    _c("img", { attrs: { src: _vm.wechatShareUrl } }),
                  ]),
                ]),
                _c("div", { staticClass: "invite-method" }, [
                  _c("p", [_vm._v("输入同事/好友邮箱，通过系统发邮件给他们")]),
                  _c("form", { attrs: { id: "inviteForm" } }, [
                    _c("div", { staticClass: "email-invite clearfix" }, [
                      _c("div", { staticClass: "email-input" }, [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.inviteEmails,
                              expression: "inviteEmails",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "emails",
                            name: "emails",
                            "data-val": "true",
                            "data-val-required": "请填写同事邮箱",
                            type: "text",
                            placeholder: "多个邮箱请用；分开",
                          },
                          domProps: { value: _vm.inviteEmails },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.inviteEmails = $event.target.value
                            },
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "invite-btn" }, [
                        _c(
                          "button",
                          {
                            staticClass: "submmit",
                            attrs: { type: "button" },
                            on: { click: _vm.handleInvite },
                          },
                          [_vm._v("邀请")]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "check-mail" }, [
                      _c("span", {
                        attrs: {
                          id: "emailError",
                          "data-valmsg-replace": "true",
                          "data-valmsg-for": "emails",
                        },
                      }),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "invite-method invite-link" }, [
                  _c("p", [_vm._v("复制你的专属邀请链接，发给更多好友")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.shareUrl,
                        expression: "shareUrl",
                      },
                    ],
                    staticClass: "invite-url",
                    attrs: { id: "share-url", type: "text" },
                    domProps: { value: _vm.shareUrl },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.shareUrl = $event.target.value
                      },
                    },
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "copy-btn js-copy-btn",
                      attrs: { "data-clipboard-target": "#share-url" },
                    },
                    [_vm._v("一键复制")]
                  ),
                ]),
              ]
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }