<template>
    <div>
        <div class="join-list">
            <div class="join-list-header">
                有
                <span>{{joinList.length}}</span>
                人申请加入，请尽快处理
            </div>
            <div class="join-list-content">
                <ul>
                    <li v-for="(item) in joinList" :key="item.id">
                        <!-- <div class="join-list"> -->
                            <div class="join-list-item item-avatar">
                                <img :src="item.avatarUrl | avatar" />
                            </div>
                            <div class="join-list-item item-info">
                                <span class="item-info-name" :title="item.realName + '@' + item.nickname">{{item.realName + '@' + item.nickname}}</span>
                                <span class="item-info-location" :title="item.location" v-if="item.location">({{item.location}})</span><br />
                                <span class="info-text">{{item.message}}</span>
                            </div>
                            <div class="join-list-item item-operate">
                                <el-dropdown @command="handleCommand">
                                    <div>
                                        <font-icon class="item-icon" href="#icon-ic_act_grey"></font-icon>
                                    </div>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item :command="composeValue(item,'accept')">同意</el-dropdown-item>
                                        <el-dropdown-item :command="composeValue(item,'refuse')">拒绝</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                        <!-- </div> -->
                    </li>
                </ul>
            </div>
        </div>

        <!-- 同意/拒绝加入弹窗 -->
        <!-- <el-dialog
            title="提示"
            :visible.sync="dialogVisible"
            width="450px"
        >
            <div class="accept-confirm">
                <div v-if="type === 'accept'">
                    <p>确定同意加入吗？</p>
                    <p>加入公司后将可以使用招聘业务，发布职位等</p>
                </div>
                <div v-if="type === 'refuse'">
                    <p>确定拒绝加入吗？</p>
                    <p>拒绝后将会自动从列表移除</p>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleCancel">取消</el-button>
                <el-button :loading="confirmLoading" type="primary" @click="handleConfirm">确定</el-button>
            </span>
        </el-dialog> -->

        <!-- 拒绝加入弹窗 -->
        <el-dialog
            title="提示"
            :visible.sync="refuseDialogVisible"
            width="450px"
        >
            <div class="accept-confirm">
                <div>
                    <p>确定拒绝加入吗？</p>
                    <p>拒绝后将会自动从列表移除</p>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleCancelRefuse">取消</el-button>
                <el-button :loading="confirmLoading" type="primary" @click="handleConfirm">确定</el-button>
            </span>
        </el-dialog>

        <!-- 同意加入弹窗 -->
        <el-dialog
            title="确认同意加入"
            class="accept-dialog"
            :visible.sync="acceptDialogVisible"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            @close="handleCancelAccept"
            width="620px"
        >
            <div class="content">
                <p class="tip">请选择 <span class="member-name">{{member.realName}}@{{member.nickname}}</span> 的</p>
                <el-form ref="acceptForm" :model="acceptForm" label-width="120px">
                    <el-form-item label="角色" class="role-form-item">
                        <el-radio-group v-model="baseRoleCode">
                            <el-radio
                                v-for="item in rolesArr"
                                :key="item.roleCode"
                                :label="item.roleCode"
                                >{{ item.roleName }}</el-radio
                            >
                            <template v-for="item in rolesCFArr">
                                <el-radio
                                    v-if="isCFuser"
                                    :key="item.roleCode"
                                    :label="item.roleCode"
                                    >{{ item.roleName }}</el-radio
                                >
                            </template>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="办公所在地">
                        <el-popover
                            placement="bottom-start"
                            popper-class="office-location-popover"
                            width="639"
                            :visible-arrow="officeLocationConfig.arrowIsShow"
                            v-model="officeLocationConfig.provinceCityIsShow"
                            trigger="click"
                        >
                            <span slot="reference" class="office-location-selected">
                                <div class="selected-option" v-if="hasSelectedCity">
                                    <span>{{ selectedOfficeLocation.text }}</span>
                                    <span class="option-remove" @click.stop="removeSelectedOfficeLocation">
                                        <i class="el-icon-close"></i>
                                    </span>
                                </div>
                                <span class="default-selected" v-if="!hasSelectedCity">请选择</span>
                            </span>

                            <province-city 
                                ref="officeLocationCity" 
                                :grid-length="7"
                                :enableHeader="officeLocationConfig.enableHeader"
                                :enableFooter="officeLocationConfig.enableFooter"
                                @confirm="handleSelectOfficeLocation"
                                @cancel="handleOfficeLocationSelectCancel"></province-city>
                        </el-popover>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button :loading="confirmLoading" @click="acceptDialogVisible=false">取消</el-button>
                <el-button :loading="confirmLoading" type="primary" @click="submit">确定</el-button>
            </span>
        </el-dialog>

        <!-- 配置角色弹窗 -->
        <!-- <el-dialog
            title="配置角色"
            :visible.sync="setRoleDialogVisible"
            @close="handleCancelSetRole"
            width="450px"
        >
            <div class="set-role">
                <div class="set-role-content">
                    <p>请选择 {{member.realName}}@{{member.nickname}} 的角色</p>
                    <el-radio-group v-model="baseRoleCode">
                        <el-radio
                            v-for="item in rolesArr"
                            :key="item.roleCode"
                            :label="item.roleCode"
                            >{{ item.roleName }}</el-radio
                        >
                    </el-radio-group>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button :loading="confirmLoading" @click="setRoleDialogVisible=false">取消</el-button>
                <el-button :loading="confirmLoading" type="primary" @click="handleConfirmSetRole">确定</el-button>
            </span>
        </el-dialog> -->
    </div>
</template>

<script>
    import { member as memberUrl } from '#/js/config/api.json';
    import UserPermissionService from '#/js/service/userPermissionService.js';
    import ProvinceCity from '#/component/common/province-city.vue';

    export default {
        name: 'JoinList',
        props: {
            joinList: {
                type: Array,
                default: []
            }
        },
        components: {
            ProvinceCity,
        },
        watch: {
            // baseRoleCode(val){
            //     console.log(val)
            // }

            'officeLocationConfig.provinceCityIsShow': {
                handler(newV, oldV) {
                    // console.log("watch", newV, oldV);
                    if(newV) { // 办公所在地城市选择框出现的时候
                        console.log(this.hasSelectedCity);
                        if(this.hasSelectedCity) {
                            // console.log(this.frequentLocation);
                            // console.log(this.selectedOfficeLocation);
                            // console.log(this.$refs.officeLocationCity);
                            let _selectcities = [];
                            _selectcities.push({
                                // id: this.frequentLocation,
                                id: this.selectedOfficeLocation.id,
                                selected: true,
                                text: this.selectedOfficeLocation.text,
                            });
                            this.$nextTick(() => {
                                this.$refs.officeLocationCity.selectedCity = _selectcities;
                            });
                        }
                    }
                },
                immediate: true,
                deep: true
            },
        },
        computed: {
            verificationToken() {
                return this.$store.state.verificationToken.verificationToken;
            },
            isCFuser(){
                return this.$store.state.user.userInfo.isCFUser;
            }, 
        },
        data() {
            return {
                acceptForm: {},

                frequentLocation: 0,
                hasSelectedCity: false,
                officeLocationConfig: {
                    enableHeader: true,
                    enableFooter: true,
                    provinceCityIsShow: false,
                    arrowIsShow: false,
                },
                selectedOfficeLocation: {
                    text: '全国',
                    id: '',
                    isSelect: true,
                },
                
                type: 'accept',
                member: {},
                dialogVisible: false,
                acceptDialogVisible: false,
                refuseDialogVisible: false,
                // setRoleDialogVisible: false,
                confirmLoading: false,
                baseRoleCode: "HeadHunters",
                rolesArr: [
                    {
                        roleName: "猎头",
                        roleCode: "HeadHunters",
                    },
                    {
                        roleName: "财务",
                        roleCode: "Financial",
                    },
                    {
                        roleName: "行政",
                        roleCode: "Executive",
                    },
                    {
                        roleName: "内控",
                        roleCode: "InternalControl",
                    },
                    {
                        roleName: "平台运营",
                        roleCode: "PlatformOperationer",
                    },
                    {
                        roleName: "HRBP",
                        roleCode: "BRHRBP",
                    },
                    // {
                    //     roleName: "数据分析",
                    //     roleCode: "DataAnalyst",
                    // },
                ],
                rolesCFArr: [
                    {
                        roleName: "数据分析",
                        roleCode: "DataAnalyst",
                    },
                ],
                // 是否点击了确认按钮
                dialogIsClickTure: false
            }
        },
        mounted() {
        },
        methods: {
            composeValue(item, type) {
                return {
                    'member': item,
                    'type': type
                }
            },
            // handleCancel() {
            //     this.dialogVisible = false;
            // },
            // 点击同意/拒绝加入公司的申请
            handleCommand(command) {
                // console.log(command);
                this.member = command.member;
                this.type = command.type;
                // this.dialogVisible = true;

                if(this.type === 'accept') {
                    this.acceptDialogVisible = true;
                } else if(this.type === 'refuse') {
                    this.refuseDialogVisible = true;
                }
            },
            // 点击确认同意/拒绝加入公司申请的提交按钮
            handleConfirm() {
                // let accept = this.type === 'accept' ? true : false;
                if(this.confirmLoading) {
                    return false;
                }
                this.confirmLoading = true;
                _request({
                    url: memberUrl.accept,
                    method: 'POST',
                    baseURL: "LbdOpenApi",
                    data: {
                        // status: accept ? 1 : 2, // 同意：1，拒绝：2
                        status: 2, // 拒绝
                        applicationId: this.member.applicationId,
                        // __RequestVerificationToken: this.verificationToken
                    }
                }).then(res => {
                    // if(accept) {
                    //     shortTips('已同意加入！');
                    //     this.dialogVisible = false;
                    //     window.setTimeout(()=>{
                    //         this.setRoleDialogVisible = true;
                    //     },1000);
                    // }else {
                    //     shortTips('已拒绝加入！');
                    //     // 刷新申请列表
                    //     this.$emit('refresh-accept-list');
                    //     this.dialogVisible = false;
                    // }

                    shortTips('已拒绝加入！');
                    // 刷新申请列表
                    this.$emit('refresh-accept-list');
                    // this.dialogVisible = false;
                    this.refuseDialogVisible = false;
                }).finally(() => {
                    this.confirmLoading = false;
                })
            },
            // 点击取消拒绝弹窗
            handleCancelRefuse() {
                this.refuseDialogVisible = false;
            },

            // 选择办公所在地
            handleSelectOfficeLocation() {
                console.log(this.$refs.officeLocationCity);
                if (this.$refs.officeLocationCity.selectedCity[0]) {
                    this.selectedOfficeLocation = this.$refs.officeLocationCity.selectedCity[0];
                } else {
                    this.selectedOfficeLocation = {
                        text: '全国',
                        id: '',
                        isSelect: true,
                    };
                }
                this.currentOfficeLocation = this.selectedOfficeLocation.text;
                this.frequentLocation = this.selectedOfficeLocation.id;

                if(this.$refs.officeLocationCity.selectedCity.length > 0) {
                    this.hasSelectedCity = true;
                } else {
                    this.hasSelectedCity = false;
                }

                return this.officeLocationConfig.provinceCityIsShow = false;
            },
            handleOfficeLocationSelectCancel() {
                if (this.$refs.officeLocationCity.selectedCity[0] && this.$refs.officeLocationCity.selectedCity[0].id !== this.selectedOfficeLocation.id) {
                    this.$refs.officeLocationCity.selectCity(this.selectedOfficeLocation);
                }
                this.$refs.officeLocationCity.currentIndex = undefined;
                this.$refs.officeLocationCity.selectedProvince = '';
                return this.officeLocationConfig.provinceCityIsShow = false;
            },
            // 移除选择的办公所在地
            removeSelectedOfficeLocation() {
                // console.log('移除-------------');
                this.selectedOfficeLocation = {
                    text: '全国',
                    id: '',
                    isSelect: true,
                };
                this.frequentLocation = 0;

                // 清除办公所在地城市选择弹窗的选择
                // console.log(this.$refs.officeLocationCity);
                this.$refs.officeLocationCity.selectedCity = [];
                this.$refs.officeLocationCity.currentIndex = 0;
                this.$refs.officeLocationCity.selectedProvince = '';

                this.hasSelectedCity = false;
            },
            // 点击取消同意弹窗
            handleCancelAccept() {
                this.acceptDialogVisible = false;

                this.baseRoleCode = 'HeadHunters';
                this.frequentLocation = 0;
                this.selectedOfficeLocation = {
                    text: '全国',
                    id: '',
                    isSelect: true,
                };
                // 清除办公所在地城市选择弹窗的选择
                // console.log(this.$refs.officeLocationCity);
                if(this.$refs.officeLocationCity) {
                    this.$refs.officeLocationCity.selectedCity = [];
                    this.$refs.officeLocationCity.currentIndex = 0;
                    this.$refs.officeLocationCity.selectedProvince = '';
                }

                this.hasSelectedCity = false;
            },
            submit() {
                if(!this.frequentLocation) {
                    shortTips('请选择办公所在地');
                    return;
                }

                if(this.confirmLoading) {
                    return false;
                }
                this.confirmLoading = true;
                let params = {
                    status: 1, // 同意
                    applicationId: this.member.applicationId,

                    roleCode: this.baseRoleCode,

                    frequentLocation: this.frequentLocation,
                };
                console.log(params);
                _request({
                    url: memberUrl.accept,
                    method: 'POST',
                    baseURL: "LbdOpenApi",
                    data: params
                }).then(res => {
                    shortTips('已同意加入！');
                    // 刷新申请列表
                    this.$emit('refresh-accept-list');
                    this.acceptDialogVisible = false;
                }).finally(() => {
                    this.confirmLoading = false;
                })
            },

            // // 点击设置角色的确认按钮
            // handleConfirmSetRole(){
            //     if(this.confirmLoading) {
            //         return false;
            //     }
            //     this.confirmLoading = true;
            //     let params = {
            //         baseRoleCode: this.baseRoleCode,
            //         userId: this.member.id
            //     }
            //     UserPermissionService.setNewUserRoleAssigned(params).then(res => {
            //         // console.log(res)
            //         this.confirmLoading = false;
            //         this.setRoleDialogVisible = false;
            //         // 点击了确认按钮，把dialogIsClickTure改为true
            //         this.dialogIsClickTure = true;
            //         // 刷新申请列表
            //         this.$emit('refresh-accept-list');
            //         this.$emit('refresh-member-list');
            //     }).catch(err => {
            //         this.confirmLoading = false;
            //     }).finally(() => {
            //         this.confirmLoading = false;
            //     })
            // },
            // // 点击设置角色弹窗的取消按钮或点击黑色遮罩层或点击X
            // handleCancelSetRole() {
            //     if(!this.dialogIsClickTure){
            //         if(this.confirmLoading) {
            //             return false;
            //         }
            //         this.confirmLoading = true;
            //         let params = {
            //             baseRoleCode: "HeadHunters",
            //             userId: this.member.id
            //         }
            //         UserPermissionService.setNewUserRoleAssigned(params).then(res => {
            //             // console.log(res)
            //             this.confirmLoading = false;
            //             this.setRoleDialogVisible = false;
            //             // 刷新申请列表
            //             this.$emit('refresh-accept-list');
            //             this.$emit('refresh-member-list');
            //         }).catch(err => {
            //             this.confirmLoading = false;
            //         }).finally(() => {
            //             this.confirmLoading = false;
            //         })
            //     } else {
            //         this.dialogIsClickTure = false;
            //         this.setRoleDialogVisible = false;
            //     }
            // }
        }
    }
</script>

<style lang="scss">
    .join-list {
        padding: 20px 0 0 20px;
        &-header {
            // height: 40px;
            // line-height: 40px;
            // padding-left: 36px;
            color: #666;
            // background: #F8F8F8;
            margin-bottom: 20px;

            span {
                color: #FF7200;
            }
        }

        &-content {
            ul {
                display: flex;
                justify-content: start;
                // justify-content: space-between;
                flex-wrap: wrap;
                li {
                    padding: 30px 20px;
                    margin-right: 20px;
                    margin-bottom: 20px;
                    width: 400px;
                    // width: 32%;
                    height: 120px;
                    background-color: #fff;
                    border: 1px solid #EEEEEE;
                    border-radius: 3px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    // .join-list {
                        // // padding: 25px 10px 25px 35px;
                        // // height: 90px;
                        // cursor: pointer;

                        .join-list-item {
                            display: inline-block;
                            vertical-align: top;
                        }

                        .item-avatar {
                            width: 50px;
                            // width: 5%;

                            img {
                                // width: 38px;
                                width: 50px;
                                border-radius: 100%;
                                border: 1px solid #979797;
                            }
                        }

                        .item-info{
                            // width: 73%;
                            width: 240px;
                            margin: 0 28px 0 20px;
                            line-height: 1.4;
                            font-size: 14px;
                            max-width: 210px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            vertical-align: top;
                            &-name {
                                color: #333;
                            }
                            .info-text {
                                color: #666;
                            }
                        }

                        .item-operate {
                            // visibility: hidden;
                            // width: 20%;
                            .item-icon {
                                width: 24px;
                                height: 24px;
                            }
                            // .btn {
                            //     width: 100px;
                            //     padding: 0 15px;
                            //     border-radius: 2px;
                            //     margin-right: 10px;
                            // }
                        }

                        // &:hover {
                        //     background: #F8F8F8;

                        //     .item-operate {
                        //         visibility: visible;
                        //     }
                        // }
                    // }
                }
            }
        }
    }

    .accept-dialog {
        .content {
            .tip {
                margin-top: 10px;
                margin-bottom: 20px;
                color: #444444;
                font-size: 14px;
                .member-name {
                    color: #38BC9D;
                }
            }

            .el-form-item.role-form-item {
                margin-bottom: 10px;
                .el-radio-group {
                    .el-radio {
                        margin-bottom: 10px;
                    }
                }
            }

            .el-form-item__label {
                text-align: left;
                color: #333;
            }

            .selected-option {
                position: relative;
                display: inline-block;
                border: 1px solid #d3d3d3;
                color: $primary;
                padding: 0 23px 0 7px;
                // vertical-align: top;
                height: 26px;
                line-height: 26px;

                .option-remove {
                    position: absolute;
                    width: 23px;
                    right: 0;
                    top: 0;
                    text-align: center;
                    font-size: 10px;
                    color: #ccc;
                    cursor: pointer;
                }
            }

            .default-selected {
                display: inline-block;
                border: 1px solid #d3d3d3;
                color: $primary;
                padding: 0 7px;
                height: 26px;
                line-height: 26px;
            }
        }
    }

    .set-role {
        .set-role-content {
            text-align: center;
            p {
                margin-top: 5px;
                margin-bottom: 30px;
            }
            .el-radio-group{
                margin-bottom: 20px;
            }
        }
    }
</style>

<style lang="scss">
.office-location-popover.el-popover {
    max-height: 600px;
    overflow-y: auto;
}
</style>

