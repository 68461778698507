<template>
    <el-dialog
        custom-class="add-member-dialog"
        title="添加成员"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        :visible="dialogVisible"
        v-loading="loading">
        <el-form
            ref="addMemberForm"
            class="add-member-form"
            :model="addMemberForm"
            :rules="addMemberFormRules"
            label-width="100px"
            label-position="left">
            <div class="selected-member-list-wrapper">
                <!-- <div class="selected-member-list-title">
                    已添加成员（{{selectedMemberList.length}}人）: 
                </div> -->
                <ul class="selected-member-list" v-if="selectedMemberList.length">
                    <li
                        class="selected-member-item"
                        v-for="member in foldMemberList"
                        :key="member.userId">
                        <span
                            class="selected-member-name"
                            :title="`${member.realName}@${member.nickname}`">
                            {{member.realName}}@{{member.nickname}}
                        </span>
                        <span
                            class="selected-member-remove el-icon-close"
                            @click="handleRemoveMember(member)"
                        ></span>
                    </li>
                </ul>
                <!-- <div class="selected-member-null" v-else>
                    暂未添加成员
                </div> -->
                <div
                    class="fold-more-btn-wrapper"
                    v-if="selectedMemberList.length > 6">
                    <span
                        class="fold-more-btn text-operate-btn"
                        @click="isFlodMembers = !isFlodMembers">
                        {{isFlodMembers ? '查看全部' : '收起'}}
                    </span>
                </div>
            </div>
            <el-form-item
                class="member-form-item"
                prop="userIds"
                label="选择成员">
                <!-- multiple -->
                <el-select
                    v-model="userIds"
                    multiple
                    filterable
                    ref="memberSelect"
                    class="member-select-input"
                    popper-class="member-select"
                    :filter-method="handleFilter"
                    placeholder="选择团队中包含的成员"
                    :loading="memberLoading"
                    @visible-change="toggleSelectVisible"
                >
                    <el-option
                        class="member-item"
                        v-for="item in memberFilterList"
                        :key="item.userId"
                        :title="`${item.realName}@${item.nickname}`"
                        :label="`${item.realName}@${item.nickname}`"
                        :value="item.userId"
                        @click.native="handleSelectMember(item)"
                    >
                    </el-option>
                </el-select>
                <!-- <div class="add-team-tip">
                    <span class="text-primary">Tips: </span>当前仅支持团队选择，删除团队成员即取消选择该团队
                </div> -->
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleCancel">取消</el-button>
            <el-button type="primary" @click="handleConfirm">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { team as teamUrl } from '#/js/config/api.json';

export default {
    name: 'AddMemberDialog',
    props: {
        team: Object
    },
    data() {
        return {
            dialogVisible: false,
            loading: false,
            memberLoading: false,
            userIds: [],
            addMemberForm: {
                teamId: "0",
                userIds: []
            },
            addMemberFormRules: {
                
            },
            companyTeamList: [],
            memberList: [],
            memberFilterList: [],
            teamName: "",
            selectedMemberList: [],
            isFlodMembers: true
        }
    },
    computed: {
        foldMemberList() {
            if(this.isFlodMembers) {
                return this.selectedMemberList.slice(0, 6);
            } else {
                return this.selectedMemberList;
            }
        }
    },
    mounted(){
        // console.log(this.teamId);
        // console.log(this.team);
        this.getCanJoinTeamMembers();
    },
    watch: {
        team(val) {
            // console.log(val);
            if(val && (val.teamId != undefined) && this.teamId != val.teamId) {
                this.addMemberForm.teamId = val.teamId;
                this.teamName = val.teamName;
            }
            // console.log(this.addMemberForm.teamId);
            // console.log(this.teamName);
        }
    },
    methods: {
        show() {
            this.dialogVisible = true;
            this.getCanJoinTeamMembers();
        },
        // 获取可添加团队的成员
        getCanJoinTeamMembers(){
            this.memberLoading = true;
            this.memberList = [];
            this.memberFilterList = [];
            _request({
                url: teamUrl.can_join_team_members,
                method: 'POST',
                baseURL: "LbdOpenApi",
                data: {
                    // start: 0,
                    take: -1
                }
            }).then(res => {
                this.memberLoading = false;
                // console.log(res);
                this.selectedMemberList = [];
                if(this.userIds.length) {
                    let member = {},
                        list = [];
                    for(let id of this.userIds) {
                        member = res.list.find(member => member.userId === id);
                        list.push(member);
                    }
                    this.selectedMemberList = list;
                }
                this.memberList = res.list;
                this.memberFilterList = res.list;
            }).catch(err => {
                this.memberLoading = false;
                console.log(err);
            })
        },
        
        handleFilter(val) {
            if(val) {
                this.memberFilterList = this.memberList.filter(member => 
                    member.nickname.toLowerCase().indexOf(val.toLowerCase()) > -1 || member.realName.toLowerCase().indexOf(val.toLowerCase()) > -1
                );
            } else {
                this.memberFilterList = this.memberList;
            }
        },

        // 点击取消/关闭弹窗按钮
        handleCancel() {
            this.dialogVisible = false;
            this.$refs.addMemberForm.resetFields();
            this.memberList = [];
            this.memberFilterList = [];
            this.userIds = [];
            this.isFlodMembers = true;
            this.selectedMemberList = [];
        },
        // 点击确认按钮
        handleConfirm(){
            // console.log(this.userIds);
            if(this.userIds.length == 0) {
                this.$message.error('请选择成员');
                return;
            }
            this.$refs.addMemberForm.validate(valid => {
                if(valid) {
                    this.loading = true;
                    // let params = Object.assign({}, this.addMemberForm);
                    // params.userIds = this.userIds;
                    let params = {
                        teamId: this.addMemberForm.teamId,
                        userIds: this.userIds
                    }
                    // console.log(params);
                    _request(
                        {
                            url: teamUrl.add_team_members,
                            method: "POST",
                            baseURL: "LbdOpenApi",
                            data: params
                        }
                    ).then(res => {
                        this.loading = false;
                        shortTips(`添加成功!`);
                        // let currentTeam = {
                        //     teamId: params.teamId,
                        //     teamName: this.teamName
                        // };
                        // this.$emit('refresh-member', currentTeam);
                        this.$emit('refresh-member');
                        this.handleCancel();
                    }).catch(err => {
                        this.loading = false;
                        console.log(err);
                    })
                } else {
                    console.log("error submit!");
                }
            })
        },
        // 选择成员
        handleSelectMember(member) {
            let selectedIndex = this.selectedMemberList.findIndex(item => item.userId === member.userId);
            if(selectedIndex > -1) {
                this.selectedMemberList.splice(selectedIndex, 1);
            } else {
                // this.selectedMemberList.unshift(member);
                this.selectedMemberList.push(member);
                // 把点击选择了的在列表里面删除
                this.memberFilterList = this.memberFilterList.filter((item)=>
                    !this.userIds.some((i)=>
                        i == item.userId
                    )
                )
            }
        },
        // 移除成员
        handleRemoveMember(member) {
            // console.log(member)
            let index = this.userIds.indexOf(member.userId);
            if(index > -1) {
                this.userIds.splice(index, 1);

                // 移除选的成员之后，移除的成员要重新出现在选择的下拉列表中
                this.memberFilterList.push(member);
                // console.log(this.memberFilterList)
                // push方法是push到最后一位，所以要把新的this.generalCoordinatorList数组根据名字排序
                this.clientOrder();
            }
            this.handleSelectMember(member)
        },
        // 根据名称拼音进行排序
        clientOrder(){
            let data = this.memberFilterList;
            // 非中文按照a-z排序
            data.sort((a,b) => a.realName.charCodeAt(0)-b.realName.charCodeAt(0));
            // 中文按照a-z排序
            data.sort((a,b) => a.realName.localeCompare(b.realName));
            // console.log(data);
        },
        toggleSelectVisible(isShow) {
            if(isShow) {
                let memberSelect = document.querySelector('.member-select .el-select-dropdown__wrap');
                function scrollToTop() {
                    setTimeout(() => {
                        memberSelect.scrollTop = 0;
                        memberSelect.removeEventListener('scroll', scrollToTop)
                    }, 0)
                }
                memberSelect.addEventListener('scroll', scrollToTop)
            }
        }
    }
};
</script>

<style lang="scss" scope>
.add-member-dialog.el-dialog {
    width: auto;
    .add-member-form.el-form {
        width: 570px;
        padding: 15px 20px 20px;
        .member-select-input {
            width: 100%;
            .el-select__tags {
                // max-width: 438px;
                // max-height: 120px;
                // overflow: hidden;
                // overflow-y: auto;
                & > span {
                    display: none;
                }
            }
        }
    }
    .el-form-item {
        .el-form-item__label {
            margin-bottom: 0;
        }
        .el-form-item__error {
            padding-top: 2px;
        }
        .el-cascader {
            width: 100%;
        }
    }
    .member-form-item.el-form-item {
        position: relative;
        .add-team-tip {
            padding-left: 0;
            font-size: 12px;
            line-height: 18px;
            color: #999;
            .text-primary {
                color: $primary;
            }
        }
    }
    .selected-member-list-wrapper {
        .selected-member-list {
            // margin-bottom: 0;
            margin-bottom: 30px;
            display: flex;
            flex-wrap: wrap;
            // justify-content: space-between;
            justify-content: start;
            .selected-member-item {
                // width: 250px;
                height: 24px;
                // padding: 0 10px;
                padding: 0 24px 0 10px;
                margin-top: 10px;
                margin-right: 10px;
                line-height: 24px;
                color: #38BC9D ;
                border: 1px solid #ddd;
                border-radius: 2px;
                background-color: #EBF8F5;
                position: relative;
            }
            .selected-member-name {
                display: inline-block;
                max-width: 220px;
                font-size: 14px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            .selected-member-remove {
                position: absolute;
                right: 5px;
                cursor: pointer;
                line-height: inherit;
                font-size: 16px;
                &:hover {
                    font-weight: bold;
                    color: #e21313;
                }
            }
        }
        .selected-member-null {
            text-align: center;
            line-height: 36px;
        }
        .fold-more-btn-wrapper {
            text-align: right;
            margin: 10px 0;
        }
    }
}
</style>

<style lang="scss">
.parent-team-select.el-cascader__dropdown {
    .el-cascader-menu {
        .el-cascader-menu__list {
            padding: 0;
        }
        .el-cascader-node {
            padding: 0;
            height: 40px;
            line-height: 40px;
            &.in-active-path {
                font-weight: normal;
            }
            &.in-checked-path {
                font-weight: bold;
            }
            label {
                opacity: 0;
                width: 100%;
                position: absolute;
                z-index: 10;
            }
            .el-cascader-node__label {
                position: relative;
                height: 40px;
                span {
                    display: inline-block;
                    max-width: 160px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .has-children {
                    position: absolute;
                    right: 5px;
                    line-height: 40px;
                }
            }
            .el-cascader-node__postfix {
                display: none;
            }
        }
    }
}
</style>