<template>
    <div class="member-page-container">
        <div class="member-page">
            <div class="member-page-header">
                <div class="member-page-header-left member-page-header-group">
                    <div
                        class="member-page-header-item"
                        @click="handleMemberClick"
                        v-bind:class="{'header-active': isMemberActived}"
                    >
                        公司成员({{memberCount}})
                    </div>
                    <div
                        class="member-page-header-item"
                        @click="handleTeamClick"
                        v-bind:class="{'header-active': isTeamActived}"
                    >
                        团队({{teamCount}})
                    </div>
                    <div
                        v-if="joinList.length > 0"
                        class="member-page-header-item"
                        @click="handleJoinClick"
                        v-bind:class="{'header-active': isJoinActived}"
                    >
                        申请加入({{joinList.length}})
                    </div>
                    <!-- <div
                        class="member-page-header-item"
                        v-if="isLeader"
                        @click="handlePermissionManage"
                        :class="{'header-active' : isLeaderActive}">
                        管理
                    </div> -->
                </div>
                <div class="member-page-header-right member-page-header-group">
                    <div class="member-page-header-item" v-if="isAdministrator || isMemberPermission">
                        <!-- <a type="download" :href="formatExportUrl('/MyCompany/ExportMembers?type=0')"> -->
                            <span @click="exportMemberList">导出成员列表</span>
                        <!-- </a> -->
                    </div>
                    <div class="member-page-header-item" v-if="!isLeaderActive" @click="handleShowInviteModal">
                        <span>邀请同事加入</span>
                    </div>
                    <!-- <div class="member-page-header-item" v-if="!isLeaderActive" @click="handleAddTeam">
                        <span>添加团队</span>
                    </div> -->
                </div>
            </div>
            <div class="member-page-list">
                <!-- <div class="member-page-list-menu member-page-list-item" v-if="!isJoinActived && !isTeamActived"> -->
                <div class="member-page-list-menu member-page-list-item" v-if="!isJoinActived">
                    <div v-if="isTeamActived">
                        <Tree
                            ref="teamTree"
                            v-show="isTeamActived"
                            :child-nodes="teamData"
                            alias-child-nodes="childTeams"
                            :default-expand-all="false"
                            v-on:current-node-change="handleCurrentNodeChange"
                            v-on:update-tree-width="handleUpdateWidth"
                            :select-single="true"
                            :row-width="teamWidth"
                            :expand-root-node="true"
                            key="team"
                            xxx="team"
                        ></Tree>
                    </div>
                    <div v-if="isMemberActived">
                        <Tree
                            ref="memberTree"
                            v-show="isMemberActived"
                            :child-nodes="memberData"
                            alias-child-nodes="childTeams"
                            :default-expand-all="false"
                            v-on:current-node-change="handleCurrentNodeChange"
                            v-on:update-tree-width="handleUpdateWidth"
                            :select-single="true"
                            :row-width="memberWidth"
                            :expand-root-node="true"
                            key="member"
                            xxx="member"
                        ></Tree>
                    </div>

                    <div v-if="isLeaderActive">
                        <Tree
                            ref="companyTree"
                            v-show="isLeaderActive"
                            :child-nodes="companyData"
                            alias-child-nodes="childTeams"
                            :default-expand-all="false"
                            v-on:current-node-change="handleCurrentNodeChange"
                            v-on:update-tree-width="handleUpdateWidth"
                            :autoCheck="true"
                            :row-width="memberWidth"
                            :hideCount="true"
                            :expand-root-node="true"
                            key="company"
                            xxx="company"
                            class="single-tree"
                        ></Tree>
                    </div>
                </div>
                <!-- <div class="member-page-list-content member-page-list-item" v-if="!isJoinActived && !isTeamActived"> -->
                <div class="member-page-list-content member-page-list-item" v-if="!isJoinActived">
                    <!-- @member-leave="handleRefreshMemberList" -->
                    <MemberList
                        ref="memberList"
                        v-if="isMemberActived"
                        :team="currentMemberNode"
                        :teamList="memberData"
                        @refresh-member-list="handleRefreshMemberList"
                        @jump-create-team="handleJumpCreateTeam"
                    ></MemberList>
                    <TeamList
                        ref="teamList"
                        v-if="isTeamActived"
                        :team="currentTeamNode"
                        :teamList="memberData"
                        @refresh-team-list="handleRefreshTeamList"
                    ></TeamList>
                    <!-- <TeamList
                        ref="teamList"
                        v-if="isTeamActived"
                        :team="currentTeamNode"
                        v-on:update-team="handleUpdateTeam"
                    ></TeamList> -->
                    <!-- <companyList ref="companyList" v-if="isLeaderActive" :team="currentTeamNode" v-on:update-team="handleUpdateTeam"></companyList> -->
                </div>
                <!-- <div class="join-list-wrap" v-if="!isJoinActived && isTeamActived">
                    <team-mind-map @refresh-team-count="refreshTeamCount"></team-mind-map>
                </div> -->
                <div class="join-list-wrap" v-if="isJoinActived">
                    <JoinList 
                        ref="joinList" 
                        :join-list="joinList" 
                        @refresh-member-list="handleRefreshMemberList" 
                        v-on:refresh-accept-list="handleGetJoinList"
                    ></JoinList>
                </div>
            </div>
            
        </div>
        <!-- <team-manage-dialog
            ref="teamManageDialog"
            :teamList="memberData"
            @refresh-team-list="handleRefreshTeamList"
        ></team-manage-dialog> -->
        <invite-sidebar ref="inviteArea"></invite-sidebar>
        
    </div>
</template>

<script>
    import InviteSidebar from './component/invite-sidebar.vue';
    import ProvinceCity from '#/component/common/province-city.vue';
    import MemberList from './component/member-list.vue';
    import TeamList from './component/team-list.vue';
    import JoinList from './component/join-list.vue';
    // import companyList from './component/company-list.vue';
    import Tree from '#/component/common/tree/tree.vue';
    // import TeamManageDialog from './component/team-manage-dialog.vue';
    import TeamMindMap from './component/team-mind-map.vue';

    import { member as memberUrl } from '#/js/config/api.json';

    export default {
        components: {
            InviteSidebar,
            ProvinceCity,
            MemberList,
            TeamList,
            Tree,
            JoinList,
            // companyList,
            // TeamManageDialog
            TeamMindMap
        },
        data() {
            return {
                type: 1,
                memberList: {
                    member: [],
                    enableTransfer: true,
                    memberTotal: 0
                },
                teamList: {
                    member: []
                },
                administrator: true,
                toAccept: {
                    member: []
                },
                memberCount: 0,
                teamCount: 0,
                user: {
                    avatarUrl: ''
                },
                updateTeam: {
                    teamName: '',
                    teamId: '',
                    teamMembers: []
                },
                isMemberActived: true,
                isTeamActived: false,
                isJoinActived: false,
                isLeaderActive: false,
                isLeader: false,
                currentMemberNode: null,
                currentTeamNode: {},
                memberData: [],
                teamData: [],
                companyData: [
                    {
                        teamName: '平安账号管理',
                        teamId: '',
                        level: 1,
                        membersCount: 0
                    }
                ],
                isSearching: true,
                isFirstLoad: true,
                joinList: [],
                teamWidth: 300,
                memberWidth: 300
                // teamWidth: '100%',
                // memberWidth: '100%'
            }
        },
        computed: {
            isInternalControl () {
                return this.$store.state.user.userInfo.roleCodeList?.includes('InternalControl');
            },
            userInfo() {
                return this.$store.state.user.userInfo;
            },
            isAdministrator() {
                return  this.userInfo.isAdministrator;
            },
            isMemberPermission() {
                return this.userInfo.privilegeCodeList.some(el=> el=='Member' )
            },
        },
        methods: {
            handleGetJoinList() {
                this.$api.getJoinList().then(res => {
                    this.joinList = res.data;
                })
            },
            handleGetMemberList() {
                
            }
        },
        created() {
            this.getMemberTree();
            this.getTeamTree();

            if(this.$store.state.user.userInfo.isAdministrator || this.isInternalControl){
                this.isLeader = true;
                this.handleGetJoinList();
            }
        },
        // mounted() {
        //     this.inviteArea = this.$refs.inviteArea;
        //     this.memberTree = this.$refs.memberTree;
        //     this.teamTree = this.$refs.teamTree;
        // },
        mounted() {
            let isMemberPermission = this.$store.state.user.userInfo.privilegeCodeList.some(el=>{
                return el=='Member';
            })
            if(this.$store.state.user.userInfo.isAdministrator || isMemberPermission){
                this.inviteArea = this.$refs.inviteArea;
                this.memberTree = this.$refs.memberTree;
                this.teamTree = this.$refs.teamTree;
            } else {
                shortTips("权限不足，请联系管理员")
                setTimeout(() => {
                    location.href = "/Headhunting/#/";
                }, 2000)
            }
        },
        watch: {
            memberData(val) {
                if(!this.currentMemberNode) {
                    this.$refs.memberTree && (this.$refs.memberTree.currentNode = val[0]);
                }
            },
            teamCount(val) {
                // console.log(val)
                this.teamCount = val;
            }
        },
        methods: {
            formatExportUrl(url) {
                return _host.portal + url;
            },

            // 导出成员列表
            exportMemberList() {
                _request({
                    method: 'POST',
                    url: `/MyCompany/ExportMembers?type=0`,
                    responseType: 'blob',
                    data: {}
                })
                .then(res => {
                    console.log(`Res:`, res)
                    this.downloadFile(res.data, '成员列表.xls')
                });
            },
            downloadFile(data, fileName) {
                let blob = new Blob([data], {
                    type: 'xls',
                });
                let url = window.URL.createObjectURL(blob);
                const link = document.createElement('a'); // 创建a标签
                link.href = url;
                link.download = fileName; // 重命名文件
                link.click();
                URL.revokeObjectURL(url); // 释放内存
            },

            // 成员
            handleMemberClick() {
                this.isMemberActived = true;
                this.isTeamActived = false;
                this.isJoinActived = false;
                this.isLeaderActive = false;
                this.type = 1;
                this.teamWidth = 300;
                this.memberWidth = 300;
                // this.teamWidth = '100%';
                // this.memberWidth = '100%';
                this.$nextTick(() =>{
                    this.$refs.memberTree.currentNode = this.memberData[0];
                })
                this.getMemberTree();
            },

            // 团队
            handleTeamClick() {
                this.isTeamActived = true;
                this.isMemberActived = false;
                this.isJoinActived = false;
                this.isLeaderActive = false;
                this.type = 2;
                this.teamWidth = 300;
                this.memberWidth = 300;
                // this.teamWidth = '100%';
                // this.memberWidth = '100%';
                // this.currentTeamNode = this.teamData[0];
                this.$nextTick(() =>{
                    this.$refs.teamTree.currentNode = this.teamData[0];
                })
                this.getTeamTree();
            },
            
            // 申请加入
            handleJoinClick() {
                this.isMemberActived = false;
                this.isTeamActived = false;
                this.isJoinActived = true;
                this.isLeaderActive = false;
                this.type = 3;
                this.teamWidth = 300;
                this.memberWidth = 300;
                // this.teamWidth = '100%';
                // this.memberWidth = '100%';
                // this.getTeamTree();
                this.handleGetJoinList();
            },
            
            // 点击添加团队按钮
            // handleAddTeam() {
            //     this.$refs.teamManageDialog.show('add');
            // },

            // 点击邀请同事加入按钮
            handleShowInviteModal() {
                this.inviteArea.show();
            },

            // 管理
            // handlePermissionManage(){
            //     this.isMemberActived = false;
            //     this.isTeamActived = false;
            //     this.isJoinActived = false;
            //     this.isLeaderActive = true;
            //     this.$nextTick(() =>{
            //         this.$refs.companyTree.currentNode = this.companyData[0];
            //     })
            // },

            getMemberTree() {
                this.isSearching = true;
                _request({
                    url: memberUrl.teams.replace('%p', 1),
                    method: 'GET'
                }).then(res => {
                    // this.currentMemberNode = res;
                    if(this.currentMemberNode == null) {this.currentMemberNode = res;}
                    this.memberData = [res];
                    this.memberCount = res.membersCount;
                }).finally(() =>{
                    this.isSearching = false;
                })
            },
            getTeamTree() {
                this.isSearching = true;
                _request({
                    url: memberUrl.teams.replace('%p', 2),
                    method: 'GET'
                }).then(res => {
                    if(!this.isTeamActived && !(this.addOrEdit == 'edit')){
                        this.currentTeamNode = res;
                    }
                    if(this.isTeamActived && !this.$refs.teamList.team.teamId) {
                        this.currentTeamNode = res;
                    }
                    this.teamData = [res];
                    this.teamCount = res.membersCount;
                }).finally(() =>{
                    this.isSearching = false;
                })
            },

            refreshTeamCount() {
                this.isSearching = true;
                 _request({
                    url: memberUrl.teams.replace('%p', 2),
                    method: 'GET'
                }).then(res => {
                    this.teamData = [res];
                    this.teamCount = res.membersCount;
                }).finally(() =>{
                    this.isSearching = false;
                })
            },
            
            handleCurrentNodeChange(node) {
                // console.log(node);
                if(this.isMemberActived) {
                    this.currentMemberNode = node;
                } else {
                    // this.currentTeamId = node.teamId;
                    // this.currentTeamName = node.teamName;
                    this.currentTeamNode = node;
                }
            },
            // handleUpdateTeam({team, type, fromTeamList}) {
            //     this.$refs.teamManageDialog.show(type, team);
            // },
            // handleAddChildTeam({team}) {
            //     this.$refs.teamManageDialog.show('add');
            // },
            // 刷新团队列表
            handleRefreshTeamList(team) {
                // console.log('team--------------');
                // console.log(team);
                this.getTeamTree();
                this.getMemberTree();
                if(this.$refs.teamList && team) {
                    this.currentTeamNode = team;
                    this.$refs.teamList.getTeamList();
                }
            },
            // 刷新成员列表
            handleRefreshMemberList(team) {
                // console.log('team**********');
                // console.log(team);
                this.getMemberTree();
                this.getTeamTree();
                if(this.$refs.memberList && team) {
                    this.currentMemberNode = team;
                    this.$refs.memberList.getMemberList();
                }
            },

            // 获取申请加入列表
            handleGetJoinList() {
                // this.getMemberTree();
                _request({
                    url: memberUrl.join,
                    method: 'GET',
                    baseURL: "LbdOpenApi"
                }).then(res => {
                    this.joinList = res || [];
                    if(this.joinList.length == 0) {
                        this.isMemberActived = true;
                        this.isJoinActived = false;
                        this.currentMemberNode = this.memberTree.rootNode;
                    }
                    // console.log(this.currentMemberNode);
                })
            },
            // 当团队为空时，跳到团队tab页去创建团队
            handleJumpCreateTeam(team) {
                this.addOrEdit = 'edit';
                this.isMemberActived = false;
                this.isJoinActived = false;
                this.isTeamActived = true;
                this.currentTeamNode = team;
                this.$nextTick(() => {
                    this.$refs.teamTree.currentNode = team;
                    // this.handleUpdateTeam({team: team, type: 'edit'});
                })
            },
            handleUpdateWidth(width) {
                // console.log(width);
                // if(this.isMemberActived) {
                //     this.memberWidth = width;
                // }else if(this.isTeamActived) {
                //     this.teamWidth = width;
                // }
            }
        }
    }

</script>

<style lang="scss" scoped>
.member-page-container{
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 15px 15px 30px;
}
.member-page {
    // margin: 20px;
    // margin: 20px;
    // padding: 0 20px;
    // padding: 0 0 0 20px;
    background-color: #fff;
    border-radius: 8px;

    // overflow-y: auto;
    // position: absolute;
    // top: 20px;
    // bottom: 20px;
    // left: 20px;
    // right: 20px;


    

    &-header {
        border-bottom: 1px solid #eee;
        overflow: hidden;

        &-group {
            width: 50%;

            .member-page-header-item {
                height: 60px;
                line-height: 60px;
                // padding: 0 20px;
                margin: 0 20px;
                display: inline-block;
                vertical-align: top;
                cursor: pointer;
                border-radius: 2px;
            }
        }

        &-left {
            float: left;

            .header-active {
                color: #38bc9d;
                // background-color: #f8f8f8;
                font-weight: bold;
                border-bottom: 4px solid #38bc9d;
            }
        }

        &-right {
            float: right;
            padding: 16px 0;
            padding-right: 10px;

            .member-page-header-item {
                height: 28px;
                line-height: 28px;
                float: right;
                background: #38BC9C;
                margin-right: 10px;
                color: #fff;
                width: 110px;
                text-align: center;
                padding: 0;

                &:hover {
                    background: #36B495;
                }

                a {
                    color: #fff;
                    width: 100%;
                    height: 100%;
                    display: inline-block;
                }

                // span {
                //     position: relative;
                //     padding-left: 18px;

                //     &::before {
                //         content: ' ';
                //         position: absolute;
                //         width: 16px;
                //         height: 16px;
                //         background-color: transparent;
                //         background-repeat: no-repeat;
                //         top: 0;
                //         bottom: 0;
                //         left: 0;
                //         margin: auto;
                //         background-size: 100% auto;
                //     }
                // }

                &:nth-child(1) {
                    span {
                        &::before {
                            background-image: url("~@src/assets/images/icon/icon-export.png");
                        }
                    }
                }

                &:nth-child(2) {
                    span {
                        &::before {
                            background-image: url("~@src/assets/images/icon/icon-member.png");
                        }
                    }
                }

                &:nth-child(3) {
                    span {
                        &::before {
                            background-image: url("~@src/assets/images/icon/icon-member-add.png");
                        }
                    }
                }
            }
        }
    }

    &-list {
        min-height: 800px;
        // margin-top: 20px;
        display: flex;

        // &-item {
        //     display: inline-block;
        //     vertical-align: top;
        // }

        &-menu {
            // max-height: 800px;
            width: 300px;
            // width: 18%;
            overflow-x: auto;
            // background: #f8f8f8;
            background-color:#fff;
            border-right: 1px solid #eee;
            padding-left: 15px;
        }

        &-content {
            // width: 1200px;
            width: 82%;
            margin-left: 20px;
            margin-right: 20px;
            padding-top: 20px;
        }

        .join-list-wrap {
            width: 100%;
        }
    }

    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: #eee;
    }
    ::-webkit-scrollbar-track {
        border-radius: 4px;
        background: transparent;
    }
}

.single-tree {
    height: 377px;

    .tree-node:hover{
        // color: $primary;
        color: #F5F5F5;
    }

    .is-checked{
        background-color: rgba(#38BC9D , .12)
    }

    .tree-node__checked-icon{
        display: none !important;
    }
}
</style>
