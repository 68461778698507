<template>
    <el-dialog
        custom-class="add-team-dialog"
        :title="title"
        width="600px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        :visible="dialogVisible"
        v-loading="loading"
    >
        <div class="superior-team">
            <el-form ref="saveForm" :model="saveForm" :rules="rules" v-if="type != 'deleteTeam'">
                <el-form-item label="团队名称" prop="teamName">
                    <el-input v-model="saveForm.teamName" placeholder="请输入团队名称"></el-input>
                </el-form-item>
                <el-form-item label="上级团队" prop="chooseId" v-if="type != 'addSubTeam'">
                    <el-select
                        v-model="saveForm.chooseId"
                        clearable
                        placeholder="请选择上级团队"
                        @clear="handleClear"
                        ref="selectUpResId"
                    >
                        <!-- 设置一个隐藏的下拉选项,如果不设置一个下拉选项，下面的树形组件将无法正常使用 -->
                        <el-option
                            hidden
                            :key="saveForm.chooseId"
                            :value="saveForm.chooseId"
                            :label="chooseName"
                        ></el-option>
                        <!-- :expand-on-click-node="false"  是否在点击节点的时候展开或者收缩节点， 默认值为 true，如果为 false，则只有点箭头图标的时候才会展开或者收缩节点。 -->
                        <!-- :check-on-click-node="true"  是否在点击节点的时候选中节点，默认值为 false，即只有在点击复选框时才会选中节点。 -->
                        <!-- @node-click="handleNodeClick"  节点被点击时的回调 -->
                        <!-- :data="companyTeamList" -->
                        <el-tree
                            class="my-tree"
                            :data="companyTeamList"
                            :props="defaultProps"
                            :expand-on-click-node="false"
                            :check-on-click-node="true"
                            @node-click="handleNodeClick"
                        >
                            <template slot-scope="{ data }">
                                <span :title="data.teamName">
                                    {{ data.teamName }}({{ data.membersCount }})
                                </span>
                            </template>
                        </el-tree>
                    </el-select>
                </el-form-item>
            </el-form>
            <div class="delete-team" v-else>
                <p>确定要删除吗 ?</p>
                <p>删除后该团队会从列表中移除 !</p>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleCancel">取消</el-button>
            <el-button type="primary" :loading="confirmLoading" @click="handleConfirm">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { team as teamUrl } from '#/js/config/api.json';

export default {
    name: "AddTeamDialog",
    props: {
        team: Object,
        teamListArr: {
            type: Array,
        },
    },
    data() {
        return {
            dialogVisible: false,
            confirmLoading: false,
            loading: false,
            curTeam: {},
            type: '',
            title: "添加团队",
            companyTeamList: [],
            chooseName: '',
            saveForm: {
                teamName: '',
                chooseId: ''
            },
            groupVersion: "",
            defaultProps: {
                expandTrigger: 'hover',
                checkStrictly: true,
                emitPath: false,
                value: 'teamId',
                label: 'teamName',
                children: 'childTeams',
                disabled: 'isHide'
            },
            rules: {
                teamName: [
                    { required: true, message: '请输入团队名称', trigger: 'blur' },
                    { max: 30, message: '团队名称限制30个字以内', trigger: 'blur' }
                ],
                chooseId: [
                    { required: true, message: '请选择上级团队', trigger: 'change' }
                ],
            }
        };
    },
    computed: {},
    mounted() {
    },
    watch: {
        // team(val) {
        //     console.log(val);
        // },
    },
    methods: {
        show(curTeam,type) {
            this.dialogVisible = true;
            // console.log(this.teamListArr);
            let list = {
                teamListArr: this.teamListArr
            };
            list = JSON.parse(JSON.stringify(list)); // 深拷贝
            this.companyTeamList = this.filterTeamList(list.teamListArr);

            this.getFirmTeamVersion();

            // console.log(curTeam);
            // console.log(type);
            this.curTeam = curTeam;
            this.type = type;
            if(type == 'editTeam') {
                this.title = '编辑团队';
                this.saveForm.teamName = curTeam.teamName;
                this.saveForm.chooseId = curTeam.parentTeamId;
                this.chooseName = curTeam.parentTeamName;
            } else if(type == 'deleteTeam') {
                this.title = '删除团队';
            } else if(type == 'addSubTeam') {
                this.title = '添加子团队';
            } else {
                this.title = '添加团队';
            }
        },

        // 获取团队架构乐观锁版本号码
        getFirmTeamVersion() {
            _request({
                url: teamUrl.get_firm_team_version,
                method: 'GET',
                baseURL: "LbdOpenApi",
                // data: {}
            }).then(res => {
                // console.log(res);
                this.groupVersion = res;
                // console.log(this.groupVersion);
            })
        },

        filterTeamList(teamListArr) {
            // console.log('teamListArr------------');
            // console.log(teamListArr);
            let listArr = [];
            for (let team of teamListArr) {
                if(team.childTeams) {
                    if(team.childTeams.length > 0) {
                        team.childTeams = this.filterTeamList(team.childTeams);
                    } else {
                        if(team.level > 1) {
                            delete team.childTeams;
                        }
                    }
                }
                listArr.push(team);
            }
            return listArr;
        },

        // 函数部分    
        // 节点点击事件
        handleNodeClick(data) {
            // 这里主要配置树形组件点击节点后，设置选择器的值；自己配置的数据，仅供参考
            this.chooseName = data.teamName;
            this.saveForm.chooseId = data.teamId;
            // 选择器执行完成后，使其失去焦点隐藏下拉框的效果
            this.$refs.selectUpResId.blur()
        },
        // 选择器配置可以清空选项，用户点击清空按钮时触发
        handleClear() {
            // 将选择器的值置空
            this.chooseName = '';
            this.saveForm.chooseId = '';
        },

        resetForm() {
            this.$refs.saveForm.resetFields();
        },
        // 点击弹窗取消按钮
        handleCancel() {
            this.dialogVisible = false;
            if(this.type != 'deleteTeam') {
                this.resetForm();
            }
            this.chooseName = '';
            this.saveForm.teamName = '';
            this.saveForm.chooseId = '';
        },
        // 点击弹窗确认按钮
        handleConfirm() {
            if(this.type === 'deleteTeam') {
                this.handleDeleteTeams();
                return;
            }
            let _url;
            let params;
            if(this.type === 'editTeam') {
                _url = teamUrl.edit_firm_teams;
                params = {
                    teamId: this.curTeam.teamId,
                    teamName: this.saveForm.teamName,
                    parentId: this.saveForm.chooseId,
                    groupVersion: this.groupVersion
                }
            } else if(this.type === 'addSubTeam') {
                _url = teamUrl.create_firm_teams;
                params = {
                    teamName: this.saveForm.teamName,
                    parentId: this.curTeam.teamId
                }
            } else {
                _url = teamUrl.create_firm_teams;
                params = {
                    teamName: this.saveForm.teamName,
                    parentId: this.saveForm.chooseId
                }
            }
            this.$refs.saveForm.validate((valid) => {
                if (valid) {
                    // console.log("点击确认按钮");
                    if(this.confirmLoading) {
                        return false;
                    }
                    this.confirmLoading = true;
                    _request(
                        {
                            url: _url,
                            method: 'POST',
                            baseURL: "LbdOpenApi",
                            data: params
                        }
                    ).then(res => {
                        shortTips('操作成功！');
                        this.confirmLoading = false;
                        this.dialogVisible = false;
                        this.handleCancel();
                        // 刷新列表
                        this.$emit('refresh-team');
                        // if(this.type === 'addSubTeam') {
                        //     let currentTeam = {
                        //         teamId: params.parentId,
                        //         teamName: this.curTeam.teamName
                        //     };
                        //     this.$parent.$refs.teamTree && (this.$parent.$refs.teamTree.currentNode = currentTeam);
                        //     this.$emit('refresh-team', currentTeam);
                        // } else {
                        //     this.$emit('refresh-team');
                        // }
                    }).catch((err) => {
                        this.confirmLoading = false;
                        console.log(err);
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 删除团队
        handleDeleteTeams() {
            // this.loading = true;
            this.confirmLoading = true;
            _request({
                url: teamUrl.delete_firm_teams.replace(/\%p/, this.curTeam.teamId),
                method: "POST",
                baseURL: "LbdOpenApi",
            }).then(res => {
                shortTips("删除成功!");
                // this.loading = false;
                this.confirmLoading = false;
                this.dialogVisible = false;
                // 刷新列表
                this.$emit('refresh-team');
                // this.$emit('refresh-team', {
                //     teamId: this.curTeam.parentTeamId,
                //     teamName: this.curTeam.parentTeamName
                // });
            }).catch(err => {
                // this.loading = false;
                this.confirmLoading = false;
                console.log(err);
            });
        },
    },
};
</script>

<style lang="scss" scoped>
    ::v-deep .el-tree-node__expand-icon {
        font-size: 15px;
    }

    .el-tree {
        background: #fff;
        color: #333333;
        padding: 10px 0;
    }
    .el-tree ::v-deep .el-icon-caret-right:before {
        content: "";
        display: inline-block;
        background: url('~@src/assets/images/icon/icon-plus.png') no-repeat;
        background-size: 15px 15px;
        width: 15px;
        height: 15px;
    }
    .el-tree ::v-deep .el-tree-node__expand-icon.expanded.el-icon-caret-right:before {
        content: "";
        display: inline-block;
        background: url('~@src/assets/images/icon/icon-subtract.png') no-repeat;
        background-size: 15px 15px;
        width: 15px;
        height: 15px;
    }
    ::v-deep .el-tree-node__expand-icon.expanded {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    // 不可折叠的前面不再显示icon图标
    ::v-deep .is-leaf.el-icon-caret-right:before {
        background: none;
    }

    .el-tree ::v-deep .el-icon-caret-right:after {
        // content: '----';
        content: '';
        width: 18px;
        height: 20px;
        position: absolute;
        left: 25px;
        right: auto;
        top: 12px;
        border-width: 1px;
        border-top: 1px dashed #999999;
    }
    ::v-deep .el-tree-node__content span:nth-child(2) {
        padding-left: 20px;
    }

    .el-tree > ::v-deep .el-tree-node:after {
        border-top: none;
    }
    ::v-deep .el-tree-node {
        position: relative;
        padding-left: 16px;
    }
    //节点有间隙，隐藏掉展开按钮就好了,如果觉得空隙没事可以删掉
    ::v-deep .el-tree-node__expand-icon.is-leaf {
        // color: transparent;
        display: none;
    }
    ::v-deep .el-tree-node__children {
        padding-left: 16px;
    }
    // 竖线
    ::v-deep .el-tree-node {
        &::before {
            content: '';
            width: 1px;
            height: 100%;
            position: absolute;
            left: -4px;
            right: auto;
            // top: -26px;
            bottom: 0px;
            border-width: 1px;
            border-left: 1px dashed #999999;
        }
        

        .el-tree-node {
            &::before {
                left: -4px;
            }

            .el-tree-node {
                &::before {
                    left: 15px;
                }

                .el-tree-node {
                    &::before {
                        left: 33px;
                    }

                    .el-tree-node {
                        &::before {
                            left: 52px;
                        }

                        .el-tree-node {
                            &::before {
                                left: 70px;
                            }

                            .el-tree-node {
                                &::before {
                                    left: 89px;
                                }

                                .el-tree-node {
                                    &::before {
                                        left: 107px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    // 当前层最后一个节点的竖线高度固定
    // .el-tree-node :last-child:before {
    //     height: 52px;
    // }
    // 横线
    ::v-deep .el-tree-node {
        &::after {
            content: '';
            // width: 18px;
            width: 40px;
            // height: 20px;
            position: absolute;
            left: -4px;
            right: auto;
            top: 12px;
            border-width: 1px;
            border-top: 1px dashed #999999;
        }

        .el-tree-node {
            &::after {
                left: -4px;
            }

            .el-tree-node {
                &::after {
                    left: 15px;
                }

                .el-tree-node {
                    &::after {
                        left: 33px;
                    }

                    .el-tree-node {
                        &::after {
                            left: 52px;
                        }

                        .el-tree-node {
                            &::after {
                                left: 70px;
                            }

                            .el-tree-node {
                                &::after {
                                    left: 89px;
                                }

                                .el-tree-node {
                                    &::after {
                                        left: 107px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    // 去掉最顶层的虚线，放最下面样式才不会被上面的覆盖了
    .el-tree > ::v-deep .el-tree-node:before {
        border-left: none;
    }
    .el-tree > ::v-deep .el-tree-node:after {
        border-top: none;
    }

    // .el-tree-node__content>.el-tree-node__expand-icon {
    //     padding: 0;
    // }
</style>

<style lang="scss" scope>
.add-team-dialog.el-dialog {
    .superior-team {
        .el-form-item__content {
            margin-left: 80px;
        }
        .el-select {
            width: 100%;
        }
    }
}
</style>