var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        "custom-class": "add-team-dialog",
        title: _vm.title,
        width: "600px",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "show-close": false,
        visible: _vm.dialogVisible,
      },
    },
    [
      _c(
        "div",
        { staticClass: "superior-team" },
        [
          _vm.type != "deleteTeam"
            ? _c(
                "el-form",
                {
                  ref: "saveForm",
                  attrs: { model: _vm.saveForm, rules: _vm.rules },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "团队名称", prop: "teamName" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入团队名称" },
                        model: {
                          value: _vm.saveForm.teamName,
                          callback: function ($$v) {
                            _vm.$set(_vm.saveForm, "teamName", $$v)
                          },
                          expression: "saveForm.teamName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.type != "addSubTeam"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "上级团队", prop: "chooseId" } },
                        [
                          _c(
                            "el-select",
                            {
                              ref: "selectUpResId",
                              attrs: {
                                clearable: "",
                                placeholder: "请选择上级团队",
                              },
                              on: { clear: _vm.handleClear },
                              model: {
                                value: _vm.saveForm.chooseId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.saveForm, "chooseId", $$v)
                                },
                                expression: "saveForm.chooseId",
                              },
                            },
                            [
                              _c("el-option", {
                                key: _vm.saveForm.chooseId,
                                attrs: {
                                  hidden: "",
                                  value: _vm.saveForm.chooseId,
                                  label: _vm.chooseName,
                                },
                              }),
                              _c("el-tree", {
                                staticClass: "my-tree",
                                attrs: {
                                  data: _vm.companyTeamList,
                                  props: _vm.defaultProps,
                                  "expand-on-click-node": false,
                                  "check-on-click-node": true,
                                },
                                on: { "node-click": _vm.handleNodeClick },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ data }) {
                                        return [
                                          _c(
                                            "span",
                                            { attrs: { title: data.teamName } },
                                            [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(data.teamName) +
                                                  "(" +
                                                  _vm._s(data.membersCount) +
                                                  ")\n                            "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  4171094308
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _c("div", { staticClass: "delete-team" }, [
                _c("p", [_vm._v("确定要删除吗 ?")]),
                _c("p", [_vm._v("删除后该团队会从列表中移除 !")]),
              ]),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.confirmLoading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }