var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        "custom-class": "add-member-dialog",
        title: "添加成员",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "show-close": false,
        visible: _vm.dialogVisible,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "addMemberForm",
          staticClass: "add-member-form",
          attrs: {
            model: _vm.addMemberForm,
            rules: _vm.addMemberFormRules,
            "label-width": "100px",
            "label-position": "left",
          },
        },
        [
          _c("div", { staticClass: "selected-member-list-wrapper" }, [
            _vm.selectedMemberList.length
              ? _c(
                  "ul",
                  { staticClass: "selected-member-list" },
                  _vm._l(_vm.foldMemberList, function (member) {
                    return _c(
                      "li",
                      {
                        key: member.userId,
                        staticClass: "selected-member-item",
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "selected-member-name",
                            attrs: {
                              title: `${member.realName}@${member.nickname}`,
                            },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(member.realName) +
                                "@" +
                                _vm._s(member.nickname) +
                                "\n                    "
                            ),
                          ]
                        ),
                        _c("span", {
                          staticClass: "selected-member-remove el-icon-close",
                          on: {
                            click: function ($event) {
                              return _vm.handleRemoveMember(member)
                            },
                          },
                        }),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm.selectedMemberList.length > 6
              ? _c("div", { staticClass: "fold-more-btn-wrapper" }, [
                  _c(
                    "span",
                    {
                      staticClass: "fold-more-btn text-operate-btn",
                      on: {
                        click: function ($event) {
                          _vm.isFlodMembers = !_vm.isFlodMembers
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.isFlodMembers ? "查看全部" : "收起") +
                          "\n                "
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
          ]),
          _c(
            "el-form-item",
            {
              staticClass: "member-form-item",
              attrs: { prop: "userIds", label: "选择成员" },
            },
            [
              _c(
                "el-select",
                {
                  ref: "memberSelect",
                  staticClass: "member-select-input",
                  attrs: {
                    multiple: "",
                    filterable: "",
                    "popper-class": "member-select",
                    "filter-method": _vm.handleFilter,
                    placeholder: "选择团队中包含的成员",
                    loading: _vm.memberLoading,
                  },
                  on: { "visible-change": _vm.toggleSelectVisible },
                  model: {
                    value: _vm.userIds,
                    callback: function ($$v) {
                      _vm.userIds = $$v
                    },
                    expression: "userIds",
                  },
                },
                _vm._l(_vm.memberFilterList, function (item) {
                  return _c("el-option", {
                    key: item.userId,
                    staticClass: "member-item",
                    attrs: {
                      title: `${item.realName}@${item.nickname}`,
                      label: `${item.realName}@${item.nickname}`,
                      value: item.userId,
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.handleSelectMember(item)
                      },
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleConfirm } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }