<template>
    <div class="team-mind-map" v-loading="loading">
        <div class="team-mind-map-title">
            <span>快捷键说明：新增节点(Enter/Tab)、删除节点(Backspace)、拖拽可视区域(空格键+鼠标左键)</span>
            <span style="color:#38BC9C">【注】：团队名称限制30个字之内</span>
        </div>
        <div class="team-mind-map-main">
            <lbd-mindmap 
                ref="lbdMindmap"
                v-model="data"
                :strokeWidth="1"
                :showUndo="false"
                :editable="editable"
                rootId="00000000-0000-0000-0000-000000000000"
                separator=","
                uuidSeparator="$"
                :textMaxLength="30"
                @change="handleChange"
                @add="handleAdd"
                @insert="handleInsert"
                @del="handleDelete"
                @edit="handleEdit"
                @move="handleMove"
                @reparent="handleReparent"
            ></lbd-mindmap>
        </div>
        <div class="team-mind-map-footer">
            <el-button type="primary" v-if="!editable" @click="enterEditMode">编辑</el-button>
            <el-button type="normal" v-if="editable" @click="handleCancel">取消</el-button>
            <el-button type="primary" v-if="editable" @click="handleSubmit">提交</el-button>
        </div>
    </div>
</template>

<script>
import DepartmentService from '#/js/service/departmentService.js';
import { team as teamUrl } from '#/js/config/api.json';
import LbdMindmap from 'lbd-mindmap';
export default {
    name: "BatchCreateDepartment",
    components: { 
        LbdMindmap
    },
    data() {
        return {
            loading: false,
            editable: false,
            groupVersion: '',
            originData: [],
            data: [],
            create: [],
            update: [],
            delete: [],
        }
    },
    // watch: {
    //     data(newVal,val) {
    //         console.log('watch监听this.data的变化')
    //         console.log(newVal)
    //         console.log(val)
    //     }
    // },
    computed: {
    },
    mounted() {
        // this.getFirmTeamVersion();
        this.getTree();
    },
    methods: {
        // 获取团队架构乐观锁版本号码
        getFirmTeamVersion() {
            _request({
                url: teamUrl.get_firm_team_version,
                method: 'GET',
                baseURL: "LbdOpenApi",
                // data: {}
            }).then(res => {
                // console.log(res);
                this.groupVersion = res;
                // console.log(this.groupVersion);
            }).finally(() => {
            })
        },

        // 点击编辑按钮
        enterEditMode() {
            this.editable = true;
        },
        // 获取团队架构树
        getTree() {
            this.loading = true;
            this.$refs.lbdMindmap.init();
             _request({
                url: teamUrl.get_firm_team_tree,
                method: 'GET',
                baseURL: "LbdOpenApi",
                // data: {}
            }).then(res =>{
                this.loading = false;
                this.groupVersion = res.groupVersion;
                let arr = [res.root];
                if(arr[0].children && arr[0].children.length > 0) {
                    arr[0].children.forEach(item => {
                        if(item.children && item.children.length > 0) {
                            item._children = item.children;
                            item.children = [];
                        }
                    })
                }
                this.originData = arr;
                this.data = arr;
                // console.log(this.originData)
                // console.log(this.data)
                this.$refs.lbdMindmap.mountHandler();
            }).catch(err => {
                this.loading = false;
            })
        },
        // 重新获取团队架构树
        refreshTree() {
            this.loading = true;
            // this.$refs.lbdMindmap.init();
             _request({
                url: teamUrl.get_firm_team_tree,
                method: 'GET',
                baseURL: "LbdOpenApi",
                // data: {}
            }).then(res =>{
                this.loading = false;
                this.groupVersion = res.groupVersion;
                let arr = [res.root];
                if(arr[0].children && arr[0].children.length > 0) {
                    arr[0].children.forEach(item => {
                        if(item.children && item.children.length > 0) {
                            item._children = item.children;
                            item.children = [];
                        }
                    })
                }
                this.originData = arr;
                this.data = arr;
                // this.$refs.lbdMindmap.mountHandler();
            }).catch(err => {
                this.loading = false;
            })
        },
        getChildren(data) {
            if (data.children && data.children.length > 0) {
                return data.children;
            }
            if (data._children && data._children.length > 0) {
                return data._children;
            }
            return [];
        },
        handleChange(arr) {
            // console.log(arr)
        },
        handleAdd(parent, node) {
            console.log('add')
            // console.log(parent)
            // console.log(node)
            this.handleEdit(node, node.name);
            // this.create.push(node);
        },
        handleEdit(node, name) {
            console.log('edit')
            console.log(node)
            let deleteIndex = this.find('delete', node);
            if(deleteIndex > -1) {
                //删除节点还原
                this.$refs.lbdMindmap.toUpdate = true;
                // let data = this.findByIndex(node.index);
                let data = this.findByPathAndId(node.path, node.id, false);
                if(data) {
                    data.id = this.delete[deleteIndex].id;
                    data.path = this.delete[deleteIndex].path;
                }
                // this.delete = this.delete.filter(item => {
                //     return item.id != data.id;
                // })
                this.delete.splice(deleteIndex, 1);
                this.create = this.create.filter(item => {
                    return item.id != node.id;
                })
            }else {
                // if(node.id.indexOf('-') == -1) {
                if(node.id.indexOf('$') == -1) {
                    let updateIndex = this.find('update', node);
                    if(updateIndex > -1) {
                        let originData = this.findByPathAndId(node.path, node.id, true);
                        if(originData && originData.name == node.name) {
                            this.update = this.update.filter(item => {
                                return item.id != node.id;
                            })
                        }else {
                            this.update[updateIndex].name = node.name;
                        }
                    }else {
                        this.update.push({
                            name: node.name,
                            id: node.id,
                            path: node.path,
                            parentId: node.parentId
                        })
                    }
                }else {
                    // console.log(777777)
                    let createIndex = this.find('create', node);
                    // console.log('createIndex'+createIndex);
                    if(createIndex > -1) {
                        // console.log(88888)
                        this.create[createIndex].name = name;
                        // console.log(this.create)
                    }else {
                        // console.log(9999)
                        // console.log(this.create)
                        this.create.push(node);
                        // console.log(this.create);
                    }
                }
            }
        },
        handleInsert(nodePosition, node) {
            this.handleEdit(node, node.name);
        },
        handleDelete(node) {
            console.log(node)
            if(node.id == 0) {
                return false;
            }
            // if(node.id.indexOf('-') > -1) {
            if(node.id.indexOf('$') > -1) {
                // console.log(this.create)
                // console.log('删除新建的------')
                this.create = this.create.filter(item => {
                    return item.id != node.id;
                })
                // console.log(this.create)
            }else {
                // console.log(this.update)
                // console.log('删除现有的------')
                this.update = this.update.filter(item => {
                    return item.id != node.id;
                })
                // console.log(this.update)
                let deleteNode = { 
                    name: node.name,
                    id: node.id,
                    path: node.path,
                    parentId: node.parentId
                };
                // if(deleteNode.path.indexOf('-') == -1) {
                if(deleteNode.path.indexOf('$') == -1) {
                    let data = this.findByPathAndId(deleteNode.path, deleteNode.id, true);
                    if(data) {
                        deleteNode.name = data.name;
                    }
                }
                this.delete.push(deleteNode);
                console.log(this.delete);
            }
            if(this.getChildren(node).length > 0) {
                this.getChildren(node).forEach(item => {
                    this.handleDelete(item);
                })
            }
        },
        handleMove(del, insert) {
            console.log(del);
            console.log(insert)
        },
        handleReparent(parent, node) {
            console.log(parent)
            console.log(node)
            let reparentNode = {
                name: node.name,
                id: node.id,
                path: parent.path ? `${parent.path},${parent.id}` : parent.id,
                // path: parent.path ? `${parent.path}` : parent.id,
                parentId: parent.id
            };
            // if(parent.path == '00000000-0000-0000-0000-000000000000'){
            //     reparentNode.path = `${parent.path},${parent.id}`
            // }
            //更新导图数据实际path
            this.$refs.lbdMindmap.toUpdate = true;
            let data = this.findByIndex(node.index);
            // console.log(data)
            console.log(reparentNode);
            data.path = reparentNode.path;
            data.parentId = reparentNode.parentId;

            // if(reparentNode.id.indexOf('-') > -1) {
            if(reparentNode.id.indexOf('$') > -1) {
                //新增节点
                this.create.forEach(item => {
                    if(item.id == reparentNode.id) {
                        item.path = reparentNode.path;
                        item.parentId = reparentNode.parentId;
                    }
                })
            }else {
                //原有节点
                let index = this.find('update', reparentNode);
                if(index > -1) {
                    this.update[index].path = reparentNode.path;
                    this.update[index].parentId = reparentNode.parentId;
                }else {
                    this.update.push(reparentNode);
                }
            }
            if(this.getChildren(node).length > 0) {
                this.getChildren(node).forEach(item => {
                    this.handleReparent(reparentNode, item);
                })
            }
        },
        find(key, node) {
            // console.log(key)
            // console.log(node)
            // console.log(this[key])
            let nodeIndex = -1;
            this[key].some((item, index) => {
                // console.log(item.id)
                // console.log(node.id)
                if(key == 'delete' && item.name == node.name && item.path == node.path) {
                    nodeIndex = index;
                    return true;
                }
                if(key != 'delete' && item.id == node.id) {
                    nodeIndex = index;
                    return true;
                }
            })
            return nodeIndex;
        },
        findByIndex(index) { // 根据index找到数据
            console.log(index)
            const array = index.split('-').map(n => ~~n)
            // console.log(this.data)
            let data = this.data[0]
            // console.log(data)
            for (let i = 1; i < array.length; i++) {
                if (data && this.getChildren(data).length > 0) {
                    data = this.getChildren(data)[array[i]]
                } else { // No data matching id
                    return null
                }
            }
            return data
        },
        findByPathAndId(path, id, isOrigin) {
            // const array = path.split('.').map(n => ~~n)
            const array = path.split(',')
            let data = isOrigin ? this.originData : this.data
            for (let i = 0; i < array.length; i++) {
                if(i == 0) {
                    // data = data[array[0]];
                    data = data[0];
                }else {
                    if(data && this.getChildren(data).length > 0) {
                        let dataIndex = -1;
                        this.getChildren(data).some((item, index) => {
                            if(item.id == array[i]) {
                                dataIndex = index;
                                return true;
                            }
                        })
                        data = dataIndex > -1 ? data[dataIndex] : null;
                    }
                }
            }
            if(data && this.getChildren(data).length > 0) {
                let dataIndex = -1;
                this.getChildren(data).some((item, index) => {
                    if(item.id == id) {
                        dataIndex = index;
                        return true;
                    }
                })
                data = dataIndex > -1 ? data[dataIndex] : null;
            }
            return data;
        },
        resolveRepeatNodes(node) {
            let parentNode = this.findByIndex(node.index)

        },
        remove(key, node) {
            this[key] = this[key].filter(item => {
                return item.id !== node.id;
            })
        },
        // 编辑取消
        handleCancel() {
            this.editable = false;
            this.$refs.lbdMindmap.toUpdate = true;
            this.data = this.originData;
            this.create = [];
            this.update = [];
            this.delete = [];
        },
        // 编辑提交
        handleSubmit() {
            let deleteList = [];
            this.delete.forEach(item => {
                deleteList.push(item.id);
            })
            // console.log(deleteList);
            // deleteList = deleteList.map(item => {
            //     item = item.replace(/\$/g, "-");
            //     return item;
            // })
            // console.log(deleteList);

            this.loading = true;
            
            // console.log(this.create)
            // 深拷贝一份this.create数组，不要改变原数组，只要提交的时候改变
            let tempCreate = JSON.parse(JSON.stringify(this.create));
            let newCreate = tempCreate.map(item => {
                item.id = item.id.replace(/\$/g, "-");
                item.parentId = item.parentId.replace(/\$/g, "-");
                // if(item.path.includes('00000000-0000-0000-0000-000000000000$00000000-0000-0000-0000-000000000000')) {
                //     item.path = item.path.replace('00000000-0000-0000-0000-000000000000$00000000-0000-0000-0000-000000000000', "00000000-0000-0000-0000-000000000000");
                // }
                item.path = item.path.replace(/\$/g, "-");
                return item;
            })
            // console.log(newCreate);

            _request({
                url: teamUrl.bulk_edit_firm_teams,
                method: 'POST',
                baseURL: "LbdOpenApi",
                data: {
                    groupVersion: this.groupVersion,
                    // create: this.create,
                    create: newCreate,
                    update: this.update,
                    delete: deleteList
                }
            }).then(res => {
                this.loading = false;
                shortTips('编辑成功！');
                this.editable = false;
                this.create = [];
                this.update = [];
                this.delete = [];
                // this.getFirmTeamVersion();
                this.$emit('refresh-team-count');
                setTimeout(() => {
                    this.refreshTree();
                }, 500)
            }).catch(err => {
                this.loading = false;
            })
        }
    },
}
</script>

<style lang="scss" scoped>
    .team-mind-map {
        position: absolute;
        // top: 92px;
        // left: 0;
        // right: 0;
        // bottom: 52px;
        top: 126px;
        left: 167px;
        right: 20px;
        bottom: 52px;
        display: flex;
        flex-direction: column;
        // width: 1200px;
        // margin: 0 auto;
        // background-color:#f9f9f9;
        background-color:#fff;

        &-title {
            font-size: 18px;
            color: #444;
            // height: 82px;
            // line-height: 82px;
            height: 40px;
            line-height: 40px;

            span {
                margin-left: 20px;
                font-size: 14px;
                color: #999;
            }
        }

        &-main /deep/{
            flex: 1;
            flex-grow: 1;

            #mindmap {
                height: 100%;
                // height: 500px;

                .gButton {
                    display: none;   
                }

                .node.depth_0 > foreignObject div {
                    background-color: $primary;
                }
                .node:not(.depth_0) > foreignObject, .node:not(.depth_0) > foreignObject div {
                    background-color: #F6F6F6;
                }
                .node.depth_1 > foreignObject, .node.depth_1 > foreignObject div {
                    outline: none;
                    background-color: #E8E8E8;
                }
                #selectedNode:not(.depth_0) > foreignObject, #selectedNode:not(.depth_0) > foreignObject div {
                    background-color: rgba(56, 188, 157, 0.3);
                    // background-color: #fc7859;
                }
                #newParentNode > foreignObject {
                    border-color: #fc7859;
                }

                .gEllipsis .btn {
                    height: 4px;
                }
            }
        }

        &-footer {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            // height: 70px;
            height: 78px;
            // padding-right: 80px;
            background-color: #fff;
            border-top: 1px solid #ccc;

            .el-button--primary:last-child {
                margin-left: 46px;
            }
        }
    }
</style>