import { departmentEdit as departmentEditUrl } from '#/js/config/javaApi.json';

export default {
    getDeptRightList(params) {
        return _request({
            url: departmentEditUrl.right_list,
            method: "GET",
            baseURL: 'LbdJavaApi',
            javaProject: 'performance',
            data: params
        })
    },
    setUserRight(params) {
        return _request({
            url: departmentEditUrl.set_user_right,
            method: "POST",
            baseURL: 'LbdJavaApi',
            javaProject: 'performance',
            data: params
        })
    },
    editRightCustomers(params) {
        return _request({
            url: departmentEditUrl.edit_right_customers,
            method: "POST",
            baseURL: 'LbdJavaApi',
            javaProject: 'performance',
            data: params
        })
    },
    // setUserStatus(params) {
    //     return _request({
    //         url: jdtUrl.user_status_set.replace(/\%p/ig, params.userId),
    //         method: "POST",
    //         data: {
    //             isShowInJDT: params.isShowInJDT
    //         }
    //     })
    // },
    // getAdministratorJdtData(params) {
    //     return _request({
    //         url: jdtJavaUrl.administrator_jdt_data,
    //         method: "GET",
    //         baseURL: 'LbdJavaApi',
    //         javaProject: 'performance',
    //         data: params
    //     })
    // },
    // getJdtStatisticsData(params) {
    //     return _request({
    //         url: jdtJavaUrl.jdt_statistics_detail,
    //         method: "POST",
    //         baseURL: 'LbdJavaApi',
    //         javaProject: 'performance',
    //         data: params
    //     })
    // }
    // 成员角色列表
    getMembersRolesList(params) {
        return _request({
            url: departmentEditUrl.members_roles_list,
            method: "GET",
            baseURL: 'LbdJavaApi',
            javaProject: 'performance',
            data: params
        })
    },
    // 编辑成员角色
    editMembersRole(params) {
        return _request({
            url: departmentEditUrl.edit_members_role,
            method: "POST",
            baseURL: 'LbdJavaApi',
            javaProject: 'performance',
            data: params
        })
    },
    // 设置新用户的角色
    setNewUserRoleAssigned(params) {
        return _request({
            url: departmentEditUrl.set_new_user_role_assigned,
            method: "POST",
            baseURL: 'LbdJavaApi',
            javaProject: 'performance',
            data: params
        })
    },
    // 角色权限列表
    getRolePrivilegeList(params) {
        return _request({
            url: departmentEditUrl.role_privilege_list,
            method: "GET",
            baseURL: 'LbdJavaApi',
            javaProject: 'performance',
            data: params
        })
    },
    // 编辑角色权限
    editRolePrivilege(params) {
        return _request({
            url: departmentEditUrl.edit_role_privilege,
            method: "POST",
            baseURL: 'LbdJavaApi',
            javaProject: 'performance',
            data: params
        })
    },
    // 获取事业线数据
    getBusinessLineList(params) {
        return _request({
            url: departmentEditUrl.get_business_line_list,
            method: "GET",
            baseURL: 'LbdJavaApi',
            javaProject: 'performance',
            data: params
        })
    },
}
