<template>
    <div class="invite-sidebar a">
        <div class="invite-box">
            <el-dialog
                class="invite-box-dialog"
                title=""
                :visible.sync="dialogVisible"
                width="495px"
            >
                <div class="invite-content" v-loading="loading">
                    <i class="invite-close" title="关闭" @click="hide"></i>
                    <div class="invite-secret clearfix">
                        <div class="invite-intro">扫码后转发到微信好友 / 微信群</div>
                        <div class="invite-qrcode">
                            <img v-bind:src="wechatShareUrl" />
                        </div>
                    </div>
                    <div class="invite-method">
                        <p>输入同事/好友邮箱，通过系统发邮件给他们</p>

                        <form id="inviteForm">
                            <div class="email-invite clearfix">
                                <div class="email-input">
                                    <textarea id="emails" name="emails" class="form-control" data-val="true" data-val-required="请填写同事邮箱" v-model="inviteEmails" type="text" placeholder="多个邮箱请用；分开"></textarea>
                                </div>
                                <div class="invite-btn">
                                    <button class="submmit" type="button" @click="handleInvite">邀请</button>
                                </div>
                            </div>
                            <div class="check-mail">
                                <span id="emailError" data-valmsg-replace="true" data-valmsg-for="emails"></span>
                            </div>
                        </form>

                    </div>
                    <div class="invite-method invite-link">
                        <p>复制你的专属邀请链接，发给更多好友</p>
                        <input id="share-url" class="invite-url" type="text" v-model="shareUrl" />
                        <span data-clipboard-target="#share-url" class="copy-btn js-copy-btn">一键复制</span>
                    </div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import Clipboard from 'clipboard';
import {invitation as invitationUrl} from '#/js/config/api.json';

export default {
    components: {
    },

    data() {
        return {
            dialogVisible: false,
            loading: false,
            emailReg: /^(\w+([-+.'']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*,?)+$/gi,
            shareUrl: '',
            wechatShareUrl: '',
            inviteEmails: ''
        }
    },

    created() {
        this.getInvitationUrl();
    },

    mounted() {
        this.invite();
    },

    methods: {

        show() {
            this.dialogVisible = true;
        },

        invite() {
            var clipboard = new Clipboard('.js-copy-btn');

            clipboard.on('success', function(e) {
                shortTips('复制成功');
            });

            clipboard.on('error', function(e) {
                shortTips('复制失败');
            });
        },

        checkMail($obj) {
            var $error = $("#emailError"),
                text = $obj.val(),
                emailReg = this.emailReg;
                emailReg.lastIndex = 0;

            if (!emailReg.test(text)) {
                $error.html("邮箱格式不正确");
                return false;
            } else {
                $error.html("");
                return true;
            }
        },

        hide() {
            this.dialogVisible = false;
        },

        getInvitationUrl() {
            _request({
                url: invitationUrl.url,
                method: 'POST'
            }).then(res => {
                // this.shareUrl = res.invitationUrl;
                this.shareUrl = decodeURIComponent(res.invitationUrl);
                this.wechatShareUrl = _host.portal + "/Sharing/QRCode?url=" + res.invitationShareUrl;
            })
        },

        handleInvite() {
            let emails = this.inviteEmails;
            let url = invitationUrl.invite_register;

            if(!emails) {
                shortTips('邮箱不能为空');
                return ;
            }

            if(_isDangerousString(emails)) {
                shortTips('邮箱地址包含危险字符');
                return ;
            }

            emails = emails.replace(/;/g, ',');
            if(this.emailReg.test(emails)) {
                console.log('正确邮箱是：', emails);
                this.loading = true;

                _request({
                    url: url,
                    method: 'POST',
                    data:  {
                        emails: emails
                    }
                }).then(res => {
                    shortTips('邀请邮件已发送！');
                    this.inviteEmails = '';
                }).finally(() => {
                    this.loading = false;
                })
            }else {
                console.log('邮箱是：', emails);
                shortTips('邮箱格式不正确，请重新输入');
                return ;
            }
        }
  }
}
</script>

<style lang="scss" scoped>
.invite-sidebar {
    // background: #b4f4fe url(//hstatic.hirede.com/lbd/images/activity/20160806/invite-aside-header.jpg) no-repeat center 0;
    // position: relative;
    // height: 225px;
    // margin: 20px auto;
    // .know-rule {
    //     margin: auto;
    //     width: 60px;
    //     position: absolute;
    //     top: 65px;
    //     left: 121px;
    // }
    .invite-box {
        // background: #fff;
        // border-radius: 15px;
        // margin: auto;
        // padding: 15px 20px 0;
        // position: absolute;
        // left: 0;
        // right: 0;
        // bottom: 15px;
        // width: 92%;
        // text-align: center;

        .green-font {
            color: $primary;
            font-weight: bold;
        }

        p {
            margin: 0 auto;
            width: 93%;
        }

        .btn-stage {
            margin: 10px auto 0;
            width: 93%;
        }

        .invite-btn {
            border: $primary 1px solid;
            border-radius: 12px;
            cursor: pointer;
            display: inline-block;
            color: $primary;
            font-size: 12px;
            padding: 0 5px;
        }

        .icon-wechat {
            background: url(//hstatic.hirede.com/lbd/images/activity/20160726/spirit.png) no-repeat -33px -79px;
            background-size: 180px auto;
            display: inline-block;
            vertical-align: middle;
            width: 25px;
            height: 19px;
        }

        .lottery-secrets {
            width: 70%;
            height: 25px;
            margin: 10px auto;
            border: 1px solid #38bc9c;
            color: $primary;
            text-align: center;
            border-radius: 15px;
            line-height: 25px;
            cursor: pointer;

            .fa {
                color: #38bc9c;
                padding-right: 5px;
                font-size: 16px;
            }
        }

        .invite-content {
            width: 495px;
            height: 575px;
            background: url(//hstatic.hirede.com/lbd/images/activity/2016090901.jpg);
            border-radius: 25px;
            position: relative;
            font-family: 'Microsoft YaHei';
            color: #fff;
            position: relative;

            .invite-close {
                position: absolute;
                top: 20px;
                right: 20px;
                width: 15px;
                height: 16px;
                cursor: pointer;

                &:before,
                &:after {
                    content: " ";
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform-origin: center;
                    width: 100%;
                    border-top: 2px solid #fff;
                }

                &:before {
                    transform: rotate(45deg);
                }

                &:after {
                    transform: rotate(-45deg);
                }
            }

            p {
                margin: 0;
            }
            .invite-secret {
                padding: 110px 0 50px;
                width: 395px;
                position: relative;

                .invite-intro {
                    padding: 40px 0 0 54px;
                    color: #fff;
                    width: 255px;
                    text-align: left;
                    float: left;
                }

                .invite-qrcode {
                    float: left;
                    margin-top: -58px;
                    width: 78px;
                    height: 78px;
                    margin-left: 335px;
                }
            }

            .invite-method {
                padding: 45px 0 0 50px;
                position: relative;

                p {
                    margin-bottom: 10px;
                }
                input {
                    border: none;
                    padding-left: 10px;
                    height: 38px;
                    font-family: Microsoft YaHei;
                    font-size: 12px;
                }
                .invite-url {
                    height: 38px;
                    font-size: 12px;
                }

                .copy-btn {
                    line-height: 18px;
                    padding: 10px;
                    background-color: #2ec4ca;
                    color: #fff;
                    display: inline-block;
                    cursor: pointer;
                    height: 38px;
                    margin-top: 1px;
                }

                .check-mail {
                    color: #ff493c;
                    height: 12px;
                }

                .email-input {
                    float: left;
                    width: 353px;
                    height: 38px;
                    overflow: hidden;

                    textarea {
                        border: 0;
                        width: 351px;
                        padding-left: 10px;
                        resize: none;
                    }
                }

                .invite-btn {
                    float: left;
                    cursor: pointer;
                    color: $primary;
                    font-size: 12px;
                    padding: 0 5px;
                    border: none;

                    button {
                        padding-top: 3px;
                        border: none;
                        line-height: 38px;
                        margin-left: -6px;
                        padding: 0 10px;
                        background-color: #2ec4ca;
                        color: #fff;
                        outline: none;
                    }

                }
            }

            .invite-link {
                padding-top: 78px;
                position: relative;

                input {
                    width: 323px;
                    outline: medium;
                    color: #333;
                }
            }
        }

    }

    .select2-search-field input {
        padding: 0 0 0 10px !important;
        margin: 0 !important;
    }

    ul.select2-search-choices {
        height: 48px;
        white-space: nowrap !important;
    }
    .seclect2-focused {
        width: 350px !important;
        border-radius: 0 !important;
    }
    .select2-choices {
        background-image: none !important;
    }
    .select2-default input {
        height: 48px !important;
        padding-left: 10px !important;
    }
    .form-control.select2-container .select2-choices {
        border-radius: 0;
    }
    .select2-container-multi .select2-choices {
        white-space: nowrap !important;
    }
    .select2-container-multi .select2-choices li {
        display: inline-block !important;
        float: none !important;
    }
    .select2-default {
        font-size: 12px !important;
    }
    .form-control {
        border: none !important;
    }
}
</style>
<style lang="scss">
.invite-box-dialog {
    &.el-dialog__wrapper .el-dialog {
        border-radius: 25px;
    }
    .el-dialog__header {
        display: none;
    }
    .el-dialog__body {
        padding: 0;
    }
}
</style>
