var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "team-mind-map",
    },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "team-mind-map-main" },
        [
          _c("lbd-mindmap", {
            ref: "lbdMindmap",
            attrs: {
              strokeWidth: 1,
              showUndo: false,
              editable: _vm.editable,
              rootId: "00000000-0000-0000-0000-000000000000",
              separator: ",",
              uuidSeparator: "$",
              textMaxLength: 30,
            },
            on: {
              change: _vm.handleChange,
              add: _vm.handleAdd,
              insert: _vm.handleInsert,
              del: _vm.handleDelete,
              edit: _vm.handleEdit,
              move: _vm.handleMove,
              reparent: _vm.handleReparent,
            },
            model: {
              value: _vm.data,
              callback: function ($$v) {
                _vm.data = $$v
              },
              expression: "data",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "team-mind-map-footer" },
        [
          !_vm.editable
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.enterEditMode },
                },
                [_vm._v("编辑")]
              )
            : _vm._e(),
          _vm.editable
            ? _c(
                "el-button",
                { attrs: { type: "normal" }, on: { click: _vm.handleCancel } },
                [_vm._v("取消")]
              )
            : _vm._e(),
          _vm.editable
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
                [_vm._v("提交")]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "team-mind-map-title" }, [
      _c("span", [
        _vm._v(
          "快捷键说明：新增节点(Enter/Tab)、删除节点(Backspace)、拖拽可视区域(空格键+鼠标左键)"
        ),
      ]),
      _c("span", { staticStyle: { color: "#38BC9C" } }, [
        _vm._v("【注】：团队名称限制30个字之内"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }