var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "member-page-container" },
    [
      _c("div", { staticClass: "member-page" }, [
        _c("div", { staticClass: "member-page-header" }, [
          _c(
            "div",
            { staticClass: "member-page-header-left member-page-header-group" },
            [
              _c(
                "div",
                {
                  staticClass: "member-page-header-item",
                  class: { "header-active": _vm.isMemberActived },
                  on: { click: _vm.handleMemberClick },
                },
                [
                  _vm._v(
                    "\n                    公司成员(" +
                      _vm._s(_vm.memberCount) +
                      ")\n                "
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "member-page-header-item",
                  class: { "header-active": _vm.isTeamActived },
                  on: { click: _vm.handleTeamClick },
                },
                [
                  _vm._v(
                    "\n                    团队(" +
                      _vm._s(_vm.teamCount) +
                      ")\n                "
                  ),
                ]
              ),
              _vm.joinList.length > 0
                ? _c(
                    "div",
                    {
                      staticClass: "member-page-header-item",
                      class: { "header-active": _vm.isJoinActived },
                      on: { click: _vm.handleJoinClick },
                    },
                    [
                      _vm._v(
                        "\n                    申请加入(" +
                          _vm._s(_vm.joinList.length) +
                          ")\n                "
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "member-page-header-right member-page-header-group",
            },
            [
              _vm.isAdministrator || _vm.isMemberPermission
                ? _c("div", { staticClass: "member-page-header-item" }, [
                    _c("span", { on: { click: _vm.exportMemberList } }, [
                      _vm._v("导出成员列表"),
                    ]),
                  ])
                : _vm._e(),
              !_vm.isLeaderActive
                ? _c(
                    "div",
                    {
                      staticClass: "member-page-header-item",
                      on: { click: _vm.handleShowInviteModal },
                    },
                    [_c("span", [_vm._v("邀请同事加入")])]
                  )
                : _vm._e(),
            ]
          ),
        ]),
        _c("div", { staticClass: "member-page-list" }, [
          !_vm.isJoinActived
            ? _c(
                "div",
                { staticClass: "member-page-list-menu member-page-list-item" },
                [
                  _vm.isTeamActived
                    ? _c(
                        "div",
                        [
                          _c("Tree", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isTeamActived,
                                expression: "isTeamActived",
                              },
                            ],
                            key: "team",
                            ref: "teamTree",
                            attrs: {
                              "child-nodes": _vm.teamData,
                              "alias-child-nodes": "childTeams",
                              "default-expand-all": false,
                              "select-single": true,
                              "row-width": _vm.teamWidth,
                              "expand-root-node": true,
                              xxx: "team",
                            },
                            on: {
                              "current-node-change":
                                _vm.handleCurrentNodeChange,
                              "update-tree-width": _vm.handleUpdateWidth,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isMemberActived
                    ? _c(
                        "div",
                        [
                          _c("Tree", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isMemberActived,
                                expression: "isMemberActived",
                              },
                            ],
                            key: "member",
                            ref: "memberTree",
                            attrs: {
                              "child-nodes": _vm.memberData,
                              "alias-child-nodes": "childTeams",
                              "default-expand-all": false,
                              "select-single": true,
                              "row-width": _vm.memberWidth,
                              "expand-root-node": true,
                              xxx: "member",
                            },
                            on: {
                              "current-node-change":
                                _vm.handleCurrentNodeChange,
                              "update-tree-width": _vm.handleUpdateWidth,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isLeaderActive
                    ? _c(
                        "div",
                        [
                          _c("Tree", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isLeaderActive,
                                expression: "isLeaderActive",
                              },
                            ],
                            key: "company",
                            ref: "companyTree",
                            staticClass: "single-tree",
                            attrs: {
                              "child-nodes": _vm.companyData,
                              "alias-child-nodes": "childTeams",
                              "default-expand-all": false,
                              autoCheck: true,
                              "row-width": _vm.memberWidth,
                              hideCount: true,
                              "expand-root-node": true,
                              xxx: "company",
                            },
                            on: {
                              "current-node-change":
                                _vm.handleCurrentNodeChange,
                              "update-tree-width": _vm.handleUpdateWidth,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          !_vm.isJoinActived
            ? _c(
                "div",
                {
                  staticClass: "member-page-list-content member-page-list-item",
                },
                [
                  _vm.isMemberActived
                    ? _c("MemberList", {
                        ref: "memberList",
                        attrs: {
                          team: _vm.currentMemberNode,
                          teamList: _vm.memberData,
                        },
                        on: {
                          "refresh-member-list": _vm.handleRefreshMemberList,
                          "jump-create-team": _vm.handleJumpCreateTeam,
                        },
                      })
                    : _vm._e(),
                  _vm.isTeamActived
                    ? _c("TeamList", {
                        ref: "teamList",
                        attrs: {
                          team: _vm.currentTeamNode,
                          teamList: _vm.memberData,
                        },
                        on: { "refresh-team-list": _vm.handleRefreshTeamList },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.isJoinActived
            ? _c(
                "div",
                { staticClass: "join-list-wrap" },
                [
                  _c("JoinList", {
                    ref: "joinList",
                    attrs: { "join-list": _vm.joinList },
                    on: {
                      "refresh-member-list": _vm.handleRefreshMemberList,
                      "refresh-accept-list": _vm.handleGetJoinList,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _c("invite-sidebar", { ref: "inviteArea" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }