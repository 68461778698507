var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "member-wrap" },
    [
      _c("div", { staticClass: "member-list" }, [
        _c("div", { staticClass: "member-list-header" }, [
          _c("div", { staticClass: "member-list-header-search" }, [
            _c(
              "div",
              { staticClass: "member-search-area" },
              [
                _c(
                  "div",
                  {
                    staticClass: "search-city-btn",
                    attrs: { title: _vm.selectedCity.text },
                  },
                  [
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "bottom-start",
                          width: "629",
                          "visible-arrow": _vm.provinceCityConfig.arrowIsShow,
                          trigger: "click",
                        },
                        model: {
                          value: _vm.provinceCityConfig.provinceCityIsShow,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.provinceCityConfig,
                              "provinceCityIsShow",
                              $$v
                            )
                          },
                          expression: "provinceCityConfig.provinceCityIsShow",
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "search-city-content",
                            attrs: { slot: "reference" },
                            slot: "reference",
                          },
                          [
                            _c("i", [_vm._v(_vm._s(_vm.selectedCity.text))]),
                            _c("span", { staticClass: "el-icon-arrow-down" }),
                          ]
                        ),
                        _c("province-city", {
                          ref: "provinceCity",
                          attrs: {
                            "grid-length": 7,
                            enableHeader: _vm.provinceCityConfig.enableHeader,
                            enableFooter: _vm.provinceCityConfig.enableFooter,
                          },
                          on: {
                            confirm: _vm.handleSelectCity,
                            cancel: _vm.handleCitySelectCancel,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("el-input", {
                  staticClass: "search-input search-input-key",
                  attrs: { placeholder: "成员名称/昵称/手机号" },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleGetMemberList.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.searchKey,
                    callback: function ($$v) {
                      _vm.searchKey = $$v
                    },
                    expression: "searchKey",
                  },
                }),
              ],
              1
            ),
            _vm.teamId != "0"
              ? _c(
                  "div",
                  {
                    staticClass: "add-member-btn",
                    on: { click: _vm.handleAddMember },
                  },
                  [_c("span", [_vm._v("添加成员")])]
                )
              : _vm._e(),
          ]),
          _c("div", { staticClass: "member-list-header-base" }, [
            _c("span", [
              _vm._v("\n                    办公所在地："),
              _c("span", { staticClass: "current-location" }, [
                _vm._v(_vm._s(_vm.listLocationName)),
              ]),
              _c("span", { staticClass: "member-count-center" }, [
                _vm._v("共 "),
                _c("span", { staticClass: "member-count" }, [
                  _vm._v(_vm._s(_vm.memberCount)),
                ]),
                _vm._v(" 人"),
              ]),
              _vm._v("\n                    管理员"),
              _c(
                "span",
                {
                  staticClass: "is-admin",
                  on: { click: _vm.handleAdminSearch },
                },
                [_vm._v(" " + _vm._s(_vm.adminCount) + " ")]
              ),
              _vm._v("人\n                "),
            ]),
          ]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading",
              },
            ],
            staticClass: "member-list-content",
          },
          [
            _vm.memberList.length > 0
              ? _c(
                  "div",
                  { staticClass: "list-content-wrap" },
                  [
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.memberList,
                          "tooltip-effect": "light myToolsTip",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: { prop: "date", label: "成员", width: "400" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "item-avatar item-common",
                                        },
                                        [
                                          _c("avatar", {
                                            attrs: {
                                              size: "sd",
                                              src: scope.row.avatarUrl,
                                              alt: scope.row.nickname,
                                              userId: scope.row.userId,
                                              enableCard: true,
                                              enableLink: true,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "item-info item-common",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "item-info-name",
                                              attrs: {
                                                title: `${scope.row.realName}@${scope.row.nickname}`,
                                              },
                                            },
                                            [
                                              _c("i", [
                                                _vm._v(
                                                  _vm._s(scope.row.realName)
                                                ),
                                              ]),
                                              _vm._v(
                                                _vm._s(
                                                  `@${scope.row.nickname}`
                                                ) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                          scope.row.isAdministrator ||
                                          scope.row.isTeamLeader
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "item-info-title",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("formatTitle")(
                                                        scope.row
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            388959864
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "teamName",
                            label: "团队",
                            "min-width": "320",
                            "show-overflow-tooltip": true,
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "frequentLocation",
                            label: "办公所在地",
                            width: "140",
                            "show-overflow-tooltip": true,
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "joinDateTime",
                            formatter: _vm.dateFormatter,
                            label: "加入公司时间",
                            width: "130",
                            align: "center",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "address",
                            label: "操作",
                            width: "160",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-dropdown",
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "opr-dropdown-link" },
                                          [
                                            _vm._v(
                                              "\n                                    选择操作"
                                            ),
                                            _c("i", {
                                              staticClass:
                                                "el-icon-arrow-down el-icon--right",
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "el-dropdown-menu",
                                          {
                                            attrs: { slot: "dropdown" },
                                            slot: "dropdown",
                                          },
                                          [
                                            !scope.row.isAdministrator &&
                                            scope.row.userId !== _vm.user.id
                                              ? _c(
                                                  "el-dropdown-item",
                                                  {
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        return _vm.handleSetMember(
                                                          scope.row,
                                                          0
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                        设置管理员\n                                    "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            scope.row.isAdministrator &&
                                            scope.row.userId !== _vm.user.id
                                              ? _c(
                                                  "el-dropdown-item",
                                                  {
                                                    staticStyle: {
                                                      color: "#FC7859",
                                                    },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        return _vm.handleSetMember(
                                                          scope.row,
                                                          1
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                        取消管理员\n                                    "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            !scope.row.isTeamLeader &&
                                            scope.row.isCanSetLeader
                                              ? _c(
                                                  "el-dropdown-item",
                                                  {
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        return _vm.handleSetMember(
                                                          scope.row,
                                                          2
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                        设置Leader\n                                    "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            scope.row.isTeamLeader &&
                                            scope.row.isCanSetLeader
                                              ? _c(
                                                  "el-dropdown-item",
                                                  {
                                                    staticStyle: {
                                                      color: "#FC7859",
                                                    },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        return _vm.handleSetMember(
                                                          scope.row,
                                                          3
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                        取消Leader\n                                    "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "el-dropdown-item",
                                              {
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.handleSetMember(
                                                      scope.row,
                                                      5
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        修改所属团队\n                                    "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-dropdown-item",
                                              {
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.handleSetMember(
                                                      scope.row,
                                                      6
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        修改办公所在地\n                                    "
                                                ),
                                              ]
                                            ),
                                            !scope.row.isAdministrator &&
                                            scope.row.userId !== _vm.user.id
                                              ? _c(
                                                  "el-dropdown-item",
                                                  {
                                                    staticStyle: {
                                                      color: "#FC7859",
                                                    },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        return _vm.handleSetMember(
                                                          scope.row,
                                                          4
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                        离职\n                                    "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    false
                                      ? _c(
                                          "div",
                                          { staticClass: "item-option" },
                                          [
                                            !scope.row.isAdministrator &&
                                            scope.row.userId !== _vm.user.id
                                              ? _c(
                                                  "span",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleSetMember(
                                                          scope.row,
                                                          0
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("设置管理员")]
                                                )
                                              : _vm._e(),
                                            !scope.row.isAdministrator &&
                                            scope.row.userId !== _vm.user.id
                                              ? _c(
                                                  "span",
                                                  { staticClass: "line" },
                                                  [_vm._v("|")]
                                                )
                                              : _vm._e(),
                                            scope.row.isAdministrator &&
                                            scope.row.userId !== _vm.user.id
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "#FC7859",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleSetMember(
                                                          scope.row,
                                                          1
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("取消管理员")]
                                                )
                                              : _vm._e(),
                                            scope.row.isAdministrator &&
                                            scope.row.userId !== _vm.user.id
                                              ? _c(
                                                  "span",
                                                  { staticClass: "line" },
                                                  [_vm._v("|")]
                                                )
                                              : _vm._e(),
                                            !scope.row.isTeamLeader &&
                                            scope.row.isCanSetLeader
                                              ? _c(
                                                  "span",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleSetMember(
                                                          scope.row,
                                                          2
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("设置Leader")]
                                                )
                                              : _vm._e(),
                                            !scope.row.isTeamLeader &&
                                            scope.row.isCanSetLeader
                                              ? _c(
                                                  "span",
                                                  { staticClass: "line" },
                                                  [_vm._v("|")]
                                                )
                                              : _vm._e(),
                                            scope.row.isTeamLeader &&
                                            scope.row.isCanSetLeader
                                              ? _c(
                                                  "span",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleSetMember(
                                                          scope.row,
                                                          3
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("取消Leader")]
                                                )
                                              : _vm._e(),
                                            scope.row.isTeamLeader &&
                                            scope.row.isCanSetLeader
                                              ? _c(
                                                  "span",
                                                  { staticClass: "line" },
                                                  [_vm._v("|")]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "span",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleSetMember(
                                                      scope.row,
                                                      5
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("修改所属团队")]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "line" },
                                              [_vm._v("|")]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleSetMember(
                                                      scope.row,
                                                      6
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("修改办公所在地")]
                                            ),
                                            !scope.row.isAdministrator &&
                                            scope.row.userId !== _vm.user.id
                                              ? _c(
                                                  "span",
                                                  { staticClass: "line" },
                                                  [_vm._v("|")]
                                                )
                                              : _vm._e(),
                                            !scope.row.isAdministrator &&
                                            scope.row.userId !== _vm.user.id
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "#FC7859",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleSetMember(
                                                          scope.row,
                                                          4
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("离职")]
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2054471965
                          ),
                        }),
                      ],
                      1
                    ),
                    _vm.memberList.length > 0
                      ? _c(
                          "el-pagination",
                          {
                            attrs: {
                              "current-page": _vm.pager.current,
                              "page-sizes": [10, 20, 50],
                              "page-size": _vm.pager.size,
                              layout: "total, sizes, prev, pager, next, slot",
                              total: _vm.memberCount,
                            },
                            on: {
                              "size-change": _vm.handleSizeChange,
                              "current-change": _vm.handleCurrentChange,
                            },
                          },
                          [
                            _c("span", { staticClass: "pagination-text" }, [
                              _c(
                                "span",
                                [
                                  _vm._v("前往"),
                                  _c("el-input", {
                                    attrs: {
                                      oninput:
                                        "value=value.replace(/^\\.+|[^\\d.]/g,'')",
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.handlePagerJump(
                                          _vm.pagerJump
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.pagerJump,
                                      callback: function ($$v) {
                                        _vm.pagerJump = $$v
                                      },
                                      expression: "pagerJump",
                                    },
                                  }),
                                  _vm._v("页"),
                                ],
                                1
                              ),
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handlePagerJump(_vm.pagerJump)
                                    },
                                  },
                                },
                                [_vm._v("跳转")]
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _c("div", { staticClass: "member-list-empty" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "empty-tip empty-item" }, [
                    _vm.teamListArr.length == 0
                      ? _c(
                          "div",
                          [
                            _c("span", [_vm._v("暂无数据")]),
                            _c("br"),
                            _c("span", [_vm._v("请前往团队模块进行团队创建~")]),
                            _c("br"),
                            _c(
                              "el-button",
                              {
                                staticClass: "empty-tip-btn",
                                attrs: { type: "primary" },
                                on: { click: _vm.handleSetEmptyTeam },
                              },
                              [
                                _c("font-icon", {
                                  attrs: { href: "#icon-add-member" },
                                }),
                                _vm._v(
                                  "\n                            去创建\n                        "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _c("div", [
                          _c("span", [_vm._v("暂无成员数据")]),
                          _c("br"),
                          _c("span", [
                            _vm._v("请点击“添加成员”按钮进行成员添加~"),
                          ]),
                          _c("br"),
                        ]),
                  ]),
                ]),
          ]
        ),
      ]),
      _c("add-member-dialog", {
        ref: "addMemberDialog",
        attrs: { team: _vm.team },
        on: { "refresh-member": _vm.refreshMember },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.dialogVisible,
            "before-close": _vm.handleCancel,
            width: "650px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          !_vm.enableHandOver
            ? _c("div", { staticClass: "member-set" }, [
                _vm.setType === 0
                  ? _c("div", [
                      _vm._v("\n                确定设置\n                "),
                      _c("span", [_vm._v(_vm._s(_vm.currentMember.realName))]),
                      _vm._v("为管理员 ？\n            "),
                    ])
                  : _vm._e(),
                _vm.setType === 1
                  ? _c("div", [
                      _vm._v("\n                确定取消\n                "),
                      _c("span", [_vm._v(_vm._s(_vm.currentMember.realName))]),
                      _vm._v("管理员权限 ？\n            "),
                    ])
                  : _vm._e(),
                _vm.setType === 2
                  ? _c("div", [
                      _vm._v("\n                确定设置\n                "),
                      _c("span", [_vm._v(_vm._s(_vm.currentMember.realName))]),
                      _vm._v(
                        "为" +
                          _vm._s(_vm.team.teamName) +
                          "团队Leader ？\n            "
                      ),
                    ])
                  : _vm._e(),
                _vm.setType === 3
                  ? _c("div", [
                      _vm._v("\n                确定取消\n                "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.currentMember.realName +
                              "@" +
                              _vm.currentMember.nickname
                          )
                        ),
                      ]),
                      _vm._v(
                        _vm._s(_vm.team.teamName) +
                          "团队Leader权限 ？\n            "
                      ),
                    ])
                  : _vm._e(),
                _vm.setType === 4
                  ? _c("div", { staticClass: "member-set-leave" }, [
                      _c("p", { staticClass: "member-set-leave-confirm" }, [
                        _vm._v(
                          "\n                    确定\n                    "
                        ),
                        _c("span", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.currentMember.realName +
                                  "@" +
                                  _vm.currentMember.nickname
                              ) +
                              "\n                "
                          ),
                        ]),
                        _vm._v(
                          "\n                    已离职，并把TA踢出本公司吗 ？\n                "
                        ),
                      ]),
                      _vm.handOverList.length > 0
                        ? _c("p", [
                            _c("span", { staticClass: "leave-tips" }, [
                              _vm._v("Tips: "),
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.currentMember.realName +
                                    "@" +
                                    _vm.currentMember.nickname
                                ) +
                                " 有负责的职位或推荐的候选人，需要安排交接人\n                "
                            ),
                          ])
                        : _vm._e(),
                      _vm.handOverList.length == 0
                        ? _c("p", [
                            _c("span", { staticClass: "leave-tips" }, [
                              _vm._v("Tips: "),
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.currentMember.realName +
                                    "@" +
                                    _vm.currentMember.nickname
                                ) +
                                " 无在进行中的业务，将会被直接踢出公司， 确定后将不可撤销\n                "
                            ),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm.setType === 5
                  ? _c(
                      "div",
                      { staticClass: "modify-owning-team" },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "saveForm",
                            attrs: { model: _vm.saveForm, rules: _vm.rules },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "所属团队", prop: "chooseId" },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    ref: "selectUpResId",
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请选择所属团队",
                                    },
                                    on: { clear: _vm.handleClear },
                                    model: {
                                      value: _vm.saveForm.chooseId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.saveForm, "chooseId", $$v)
                                      },
                                      expression: "saveForm.chooseId",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      key: _vm.saveForm.chooseId,
                                      attrs: {
                                        hidden: "",
                                        value: _vm.saveForm.chooseId,
                                        label: _vm.chooseName,
                                      },
                                    }),
                                    _c("el-tree", {
                                      staticClass: "my-tree",
                                      attrs: {
                                        data: _vm.companyTeamList,
                                        props: _vm.defaultProps,
                                        "expand-on-click-node": false,
                                        "check-on-click-node": true,
                                      },
                                      on: { "node-click": _vm.handleNodeClick },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ data }) {
                                              return [
                                                _c(
                                                  "span",
                                                  {
                                                    attrs: {
                                                      title: data.teamName,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(data.teamName) +
                                                        "(" +
                                                        _vm._s(
                                                          data.membersCount
                                                        ) +
                                                        ")\n                                    "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        66060068
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.setType === 0 || _vm.setType === 2
                  ? _c("div", { staticClass: "member-set-tip" }, [
                      _c("span", [_vm._v("TIPS：")]),
                      _vm._v(
                        "\n                管理员可以创建管理员和团队leader，且有权查看全部成员数据；团队leader可以查看团队成员数据。\n            "
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.enableHandOver
            ? _c("div", { staticClass: "hand-over-content" }, [
                _c("p", { staticClass: "hand-over-tip" }, [
                  _vm._v(
                    "选择一个成员交接 " +
                      _vm._s(
                        _vm.currentMember.realName +
                          "@" +
                          _vm.currentMember.nickname
                      ) +
                      " 的工作，选择后将不可撤销"
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "search-wrap" },
                  [
                    _c("el-input", {
                      attrs: {
                        placeholder: "请输入要搜索的姓名、昵称",
                        "prefix-icon": "el-icon-search",
                      },
                      model: {
                        value: _vm.searchText,
                        callback: function ($$v) {
                          _vm.searchText = $$v
                        },
                        expression: "searchText",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "hand-over-list" }, [
                  _c(
                    "ul",
                    _vm._l(_vm.computedHandOverList, function (item, index) {
                      return _c("li", { key: index }, [
                        _c(
                          "div",
                          {
                            staticClass: "hand-over-item-wrap",
                            on: {
                              click: function ($event) {
                                return _vm.handleSelectHandOver(item)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "hand-over-item hand-over-item-avatar",
                              },
                              [_c("img", { attrs: { src: item.avatarUrl } })]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "hand-over-item hand-over-item-info",
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "info-name",
                                    attrs: {
                                      title:
                                        item.realName + "@" + item.nickname,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        item.realName + "@" + item.nickname
                                      )
                                    ),
                                  ]
                                ),
                                _c("br"),
                                _c(
                                  "p",
                                  {
                                    staticClass: "info-description",
                                    attrs: {
                                      title: item.firmShortName + item.title,
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "info-title" }, [
                                      _vm._v(_vm._s(item.firmShortName)),
                                    ]),
                                    _vm._v(
                                      "  \n                                    "
                                    ),
                                    _c("span", { staticClass: "info-title" }, [
                                      _vm._v(_vm._s(item.title)),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.selected,
                                        expression: "item.selected",
                                      },
                                    ],
                                    staticClass: "tip-again",
                                  },
                                  [_vm._v("选择后将不可撤销，请谨慎操作")]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "hand-over-item hand-over-item-select",
                              },
                              [
                                _c("span", {
                                  class: [
                                    "info-isSelect",
                                    item.selected ? "info-select" : "",
                                  ],
                                }),
                              ]
                            ),
                          ]
                        ),
                      ])
                    }),
                    0
                  ),
                ]),
              ])
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleCancel } }, [
                _vm._v("取消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.confirmLoading },
                  on: { click: _vm.handleSetConfirm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "office-location-dialog",
          attrs: {
            title: "修改办公所在地",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            visible: _vm.officeLocationDialogVisible,
            width: "400px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.officeLocationDialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "modify-office-location" },
            [
              _c("span", [_vm._v("办公所在地")]),
              _c(
                "el-popover",
                {
                  attrs: {
                    placement: "bottom-start",
                    "popper-class": "office-location-popover",
                    width: "639",
                    "visible-arrow": _vm.officeLocationConfig.arrowIsShow,
                    trigger: "click",
                  },
                  model: {
                    value: _vm.officeLocationConfig.provinceCityIsShow,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.officeLocationConfig,
                        "provinceCityIsShow",
                        $$v
                      )
                    },
                    expression: "officeLocationConfig.provinceCityIsShow",
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "office-location-selected",
                      attrs: { slot: "reference" },
                      slot: "reference",
                    },
                    [
                      _vm.hasSelectedCity
                        ? _c("div", { staticClass: "selected-option" }, [
                            _c("span", [
                              _vm._v(_vm._s(_vm.selectedOfficeLocation.text)),
                            ]),
                            _c(
                              "span",
                              {
                                staticClass: "option-remove",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.removeSelectedOfficeLocation.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ])
                        : _vm._e(),
                      !_vm.hasSelectedCity
                        ? _c("span", { staticClass: "default-selected" }, [
                            _vm._v("请选择"),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _c("province-city", {
                    ref: "officeLocationCity",
                    attrs: {
                      "grid-length": 7,
                      enableHeader: _vm.officeLocationConfig.enableHeader,
                      enableFooter: _vm.officeLocationConfig.enableFooter,
                    },
                    on: {
                      confirm: _vm.handleSelectOfficeLocation,
                      cancel: _vm.handleOfficeLocationSelectCancel,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.confirmLoading },
                  on: { click: _vm.updateOfficeLoaction },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "empty-img empty-item" }, [
      _c("img", {
        attrs: { src: require("@src/assets/images/icon/member-empty-img.png") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }