<template>
    <div class="member-wrap">
        <div class="member-list">
            <div class="member-list-header">
                <div class="member-list-header-search">
                    <div class="member-search-area">
                        <div class="search-city-btn" :title="selectedCity.text">
                            <el-popover
                                placement="bottom-start"
                                width="629"
                                :visible-arrow="provinceCityConfig.arrowIsShow"
                                v-model="provinceCityConfig.provinceCityIsShow"
                                trigger="click"
                            >
                                <span slot="reference" class="search-city-content">
                                    <i>{{ selectedCity.text }}</i>
                                    <span class="el-icon-arrow-down"></span>
                                </span>

                                <province-city ref="provinceCity" :grid-length="7"
                                            :enableHeader="provinceCityConfig.enableHeader"
                                            :enableFooter="provinceCityConfig.enableFooter"
                                            @confirm="handleSelectCity"
                                            @cancel="handleCitySelectCancel"></province-city>
                            </el-popover>
                        </div>
                        <el-input
                            class="search-input search-input-key"
                            v-model="searchKey"
                            placeholder="成员名称/昵称/手机号"
                            @keyup.enter.native="handleGetMemberList"
                        ></el-input>
                    </div>
                    <div class="add-member-btn" v-if="teamId != '0'" @click="handleAddMember">
                        <span>添加成员</span>
                    </div>
                </div>
                <div class="member-list-header-base">
                    <span>
                        办公所在地：<span class="current-location">{{listLocationName}}</span>
                        <span class="member-count-center">共 <span class="member-count">{{memberCount}}</span> 人</span>
                        管理员<span class="is-admin" @click="handleAdminSearch"> {{adminCount}} </span>人
                    </span>
                </div>
            </div>
            <div class="member-list-content" v-loading="listLoading">
                <div v-if="memberList.length > 0" class="list-content-wrap">
                    <el-table
                        :data="memberList"
                        style="width: 100%"
                        tooltip-effect="light myToolsTip">
                        <el-table-column
                            prop="date"
                            label="成员"
                            width="400"
                        >
                            <template slot-scope="scope">
                                <div>
                                    <div class="item-avatar item-common">
                                        <avatar
                                            size="sd"
                                            :src="scope.row.avatarUrl"
                                            :alt="scope.row.nickname"
                                            :userId="scope.row.userId"
                                            :enableCard="true"
                                            :enableLink="true"
                                        ></avatar>
                                    </div>
                                    <div class="item-info item-common">
                                        <span class="item-info-name" :title="`${scope.row.realName}@${scope.row.nickname}`">
                                            <i>{{scope.row.realName}}</i>{{`@${scope.row.nickname}`}}
                                        </span>
                                        <span class="item-info-title" v-if="scope.row.isAdministrator || scope.row.isTeamLeader">{{scope.row | formatTitle}}</span>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="teamName"
                            label="团队"
                            min-width="320"
                            :show-overflow-tooltip='true'
                        >
                        </el-table-column>
                        <el-table-column
                            prop="frequentLocation"
                            label="办公所在地"
                            width="140"
                            :show-overflow-tooltip='true'
                        >
                        </el-table-column>
                        <el-table-column
                            prop="joinDateTime"
                            :formatter="dateFormatter"
                            label="加入公司时间"
                            width="130"
                            align="center"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="address"
                            label="操作"
                            width="160"
                            align="center"
                        >
                            <template slot-scope="scope">

                                <el-dropdown>
                                    <span class="opr-dropdown-link">
                                        选择操作<i class="el-icon-arrow-down el-icon--right"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item 
                                            v-if="!scope.row.isAdministrator && scope.row.userId !== user.id" 
                                            @click.native="handleSetMember(scope.row,0)">
                                            设置管理员
                                        </el-dropdown-item>
                                        <el-dropdown-item 
                                            v-if="scope.row.isAdministrator && scope.row.userId !== user.id" style="color:#FC7859" 
                                            @click.native="handleSetMember(scope.row,1)">
                                            取消管理员
                                        </el-dropdown-item>
                                        <el-dropdown-item 
                                            v-if="!scope.row.isTeamLeader && scope.row.isCanSetLeader" 
                                            @click.native="handleSetMember(scope.row,2)">
                                            设置Leader
                                        </el-dropdown-item>
                                        <el-dropdown-item 
                                            v-if="scope.row.isTeamLeader && scope.row.isCanSetLeader" style="color:#FC7859" 
                                            @click.native="handleSetMember(scope.row,3)">
                                            取消Leader
                                        </el-dropdown-item>
                                        <el-dropdown-item 
                                            @click.native="handleSetMember(scope.row,5)">
                                            修改所属团队
                                        </el-dropdown-item>
                                        <el-dropdown-item 
                                            @click.native="handleSetMember(scope.row,6)">
                                            修改办公所在地
                                        </el-dropdown-item>
                                        <el-dropdown-item 
                                            style="color:#FC7859"
                                            v-if="!scope.row.isAdministrator && scope.row.userId !== user.id" 
                                            @click.native="handleSetMember(scope.row,4)">
                                            离职
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                                <div class="item-option" v-if="false">
                                    <span v-if="!scope.row.isAdministrator && scope.row.userId !== user.id" @click="handleSetMember(scope.row,0)">设置管理员</span>
                                    <span class="line" v-if="!scope.row.isAdministrator && scope.row.userId !== user.id">|</span>

                                    <span v-if="scope.row.isAdministrator && scope.row.userId !== user.id" style="color:#FC7859" @click="handleSetMember(scope.row,1)">取消管理员</span>
                                    <span class="line" v-if="scope.row.isAdministrator && scope.row.userId !== user.id">|</span>

                                    <span v-if="!scope.row.isTeamLeader && scope.row.isCanSetLeader" @click="handleSetMember(scope.row,2)">设置Leader</span>
                                    <span class="line" v-if="!scope.row.isTeamLeader && scope.row.isCanSetLeader">|</span>

                                    <span v-if="scope.row.isTeamLeader && scope.row.isCanSetLeader" @click="handleSetMember(scope.row,3)">取消Leader</span>
                                    <span class="line" v-if="scope.row.isTeamLeader && scope.row.isCanSetLeader">|</span>

                                    <span @click="handleSetMember(scope.row,5)">修改所属团队</span>
                                    <!-- <span class="line" v-if="!scope.row.isAdministrator && scope.row.userId !== user.id">|</span> -->
                                    <span class="line">|</span>

                                    <span @click="handleSetMember(scope.row,6)">修改办公所在地</span>
                                    <span class="line" v-if="!scope.row.isAdministrator && scope.row.userId !== user.id">|</span>

                                    <span v-if="!scope.row.isAdministrator && scope.row.userId !== user.id" style="color:#FC7859" @click="handleSetMember(scope.row,4)">离职</span>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!-- 分页 -->
                    <el-pagination
                        :current-page="pager.current"
                        :page-sizes="[10, 20, 50]"
                        :page-size="pager.size"
                        layout="total, sizes, prev, pager, next, slot"
                        :total="memberCount"
                        v-if="memberList.length > 0"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                    >
                        <span class="pagination-text">
                            <span>前往<el-input oninput="value=value.replace(/^\.+|[^\d.]/g,'')" v-model="pagerJump" @keyup.enter.native="handlePagerJump(pagerJump)"></el-input>页</span>
                            <span @click="handlePagerJump(pagerJump)">跳转</span>
                        </span>
                    </el-pagination>
                </div>
                <div v-else class="member-list-empty">
                    <div class="empty-img empty-item">
                        <img src="@src/assets/images/icon/member-empty-img.png" />
                    </div>
                    <div class="empty-tip empty-item">
                        <!-- <span>暂无成员数据</span><br/>
                        <span>请前往团队模块设置成员信息哦~</span><br/>
                        <el-button class="empty-tip-btn" type="primary" @click="handleSetEmptyTeam">
                            <font-icon href="#icon-add-member"></font-icon>
                            去设置
                        </el-button> -->
                        <div v-if="teamListArr.length == 0">
                            <span>暂无数据</span><br/>
                            <span>请前往团队模块进行团队创建~</span><br/>
                            <el-button class="empty-tip-btn" type="primary" @click="handleSetEmptyTeam">
                                <font-icon href="#icon-add-member"></font-icon>
                                去创建
                            </el-button>
                        </div>
                        <div v-else>
                            <span>暂无成员数据</span><br/>
                            <span>请点击“添加成员”按钮进行成员添加~</span><br/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 添加成员 -->
        <add-member-dialog ref="addMemberDialog" :team="team" @refresh-member="refreshMember"></add-member-dialog>

        <el-dialog
            :title="title"
            :visible.sync="dialogVisible"
            :before-close="handleCancel"
            width="650px"
        >
            <div class="member-set" v-if="!enableHandOver">
                <div v-if="setType === 0">
                    确定设置
                    <span>{{currentMember.realName}}</span>为管理员 ？
                </div>
                <div v-if="setType === 1">
                    确定取消
                    <span>{{currentMember.realName}}</span>管理员权限 ？
                </div>
                <div v-if="setType === 2">
                    确定设置
                    <span>{{currentMember.realName}}</span>为{{team.teamName}}团队Leader ？
                </div>
                <div v-if="setType === 3">
                    确定取消
                    <span>{{currentMember.realName + '@' + currentMember.nickname}}</span>{{team.teamName}}团队Leader权限 ？
                </div>
                <div v-if="setType === 4" class="member-set-leave">
                    <p class="member-set-leave-confirm">
                        确定
                        <span>
                        {{currentMember.realName + '@' + currentMember.nickname}}
                    </span>
                        已离职，并把TA踢出本公司吗 ？
                    </p>
                    <p v-if="handOverList.length > 0">
                        <span class="leave-tips">Tips: </span> {{currentMember.realName + '@' + currentMember.nickname}} 有负责的职位或推荐的候选人，需要安排交接人
                    </p>
                    <p v-if="handOverList.length == 0">
                        <span class="leave-tips">Tips: </span> {{currentMember.realName + '@' + currentMember.nickname}} 无在进行中的业务，将会被直接踢出公司， 确定后将不可撤销
                    </p>
                </div>
                <div v-if="setType === 5" class="modify-owning-team">
                    <el-form ref="saveForm" :model="saveForm" :rules="rules">
                        <el-form-item label="所属团队" prop="chooseId">
                            <el-select
                                v-model="saveForm.chooseId"
                                clearable
                                placeholder="请选择所属团队"
                                @clear="handleClear"
                                ref="selectUpResId"
                            >
                                <!-- 设置一个隐藏的下拉选项,如果不设置一个下拉选项，下面的树形组件将无法正常使用 -->
                                <el-option hidden :key="saveForm.chooseId" :value="saveForm.chooseId" :label="chooseName"></el-option>
                                <!-- :expand-on-click-node="false"  是否在点击节点的时候展开或者收缩节点， 默认值为 true，如果为 false，则只有点箭头图标的时候才会展开或者收缩节点。 -->
                                <!-- :check-on-click-node="true"  是否在点击节点的时候选中节点，默认值为 false，即只有在点击复选框时才会选中节点。 -->
                                <!-- @node-click="handleNodeClick"  节点被点击时的回调 -->
                                <!-- :data="companyTeamList" -->
                                <el-tree
                                    class="my-tree"
                                    :data="companyTeamList"
                                    :props="defaultProps"
                                    :expand-on-click-node="false"
                                    :check-on-click-node="true"
                                    @node-click="handleNodeClick"
                                >
                                    <template slot-scope="{ data }">
                                        <span :title="data.teamName">
                                            {{data.teamName}}({{data.membersCount}})
                                        </span>
                                    </template>
                                </el-tree>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </div>

                <div class="member-set-tip" v-if="setType === 0 || setType === 2">
                    <span>TIPS：</span>
                    管理员可以创建管理员和团队leader，且有权查看全部成员数据；团队leader可以查看团队成员数据。
                </div>
            </div>
            <div class="hand-over-content" v-if="enableHandOver">
                <p class="hand-over-tip">选择一个成员交接 {{currentMember.realName + '@' + currentMember.nickname}} 的工作，选择后将不可撤销</p>
                <div class="search-wrap">
                    <el-input
                        placeholder="请输入要搜索的姓名、昵称"
                        prefix-icon="el-icon-search"
                        v-model="searchText">
                    </el-input>
                </div>
                <div class="hand-over-list">
                    <ul>
                        <li v-for="(item, index) in computedHandOverList" :key="index">
                            <div class="hand-over-item-wrap" @click="handleSelectHandOver(item)">
                                <div class="hand-over-item hand-over-item-avatar">
                                    <img :src="item.avatarUrl" />
                                </div>
                                <div class="hand-over-item hand-over-item-info">
                                    <span class="info-name" :title="item.realName + '@' + item.nickname">{{item.realName + '@' + item.nickname}}</span><br />
                                    <p class="info-description" :title="item.firmShortName + item.title">
                                        <span class="info-title">{{item.firmShortName}}</span>&nbsp;&nbsp;
                                        <span class="info-title">{{item.title}}</span>
                                    </p>
                                    <span class="tip-again" v-show="item.selected">选择后将不可撤销，请谨慎操作</span>
                                </div>
                                <div class="hand-over-item hand-over-item-select">
                                    <span :class="['info-isSelect', item.selected ? 'info-select' : '']"></span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleCancel">取消</el-button>
                <el-button type="primary" :loading="confirmLoading" @click="handleSetConfirm">确定</el-button>
            </span>
        </el-dialog>

        <el-dialog
            title="修改办公所在地"
            class="office-location-dialog"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :visible.sync="officeLocationDialogVisible"
            width="400px"
            :before-close="handleClose">
            <div class="modify-office-location">
                <span>办公所在地</span>
                <el-popover
                    placement="bottom-start"
                    popper-class="office-location-popover"
                    width="639"
                    :visible-arrow="officeLocationConfig.arrowIsShow"
                    v-model="officeLocationConfig.provinceCityIsShow"
                    trigger="click"
                >
                    <span slot="reference" class="office-location-selected">
                        <div class="selected-option" v-if="hasSelectedCity">
                            <span>{{ selectedOfficeLocation.text }}</span>
                            <span class="option-remove" @click.stop="removeSelectedOfficeLocation">
                                <i class="el-icon-close"></i>
                            </span>
                        </div>
                        <span class="default-selected" v-if="!hasSelectedCity">请选择</span>
                    </span>

                    <province-city 
                        ref="officeLocationCity" 
                        :grid-length="7"
                        :enableHeader="officeLocationConfig.enableHeader"
                        :enableFooter="officeLocationConfig.enableFooter"
                        @confirm="handleSelectOfficeLocation"
                        @cancel="handleOfficeLocationSelectCancel"></province-city>
                </el-popover>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取消</el-button>
                <el-button type="primary" :loading="confirmLoading" @click="updateOfficeLoaction">确定</el-button>
            </span>
        </el-dialog>
        
        
    </div>
</template>

<script>
    import ProvinceCity from '#/component/common/province-city.vue';
    import Avatar from '#/component/common/avatar.vue';
    import { member as memberUrl, team as teamUrl } from '#/js/config/api.json';
    import AddMemberDialog from './add-member-dialog.vue';

    const MEMBER_SET ={
        0: {key: 'isAdministrator', value: true},
        1: {key: 'isAdministrator', value: false},
        2: {key: 'isTeamLeader', value: true},
        3: {key: 'isTeamLeader', value: false}
    }

    export default {
        name: 'MemberList',
        props: {
            team: Object,
            teamList: {
                type: Array,
            },
        },
        components: {
            ProvinceCity,
            Avatar,
            AddMemberDialog
        },
        data() {
            return {
                pager: {
                    current: 1,
                    size: 20,
                },
                pagerJump: 0,
                teamListArr: [],
                // managingTeamId: '',
                chooseName: '',
                saveForm: {
                    chooseId: ''
                },
                rules: {
                    chooseId: [
                        { required: true, message: '请选择所属团队', trigger: 'change' }
                    ],
                },
                companyTeamList: [],
                defaultProps: {
                    expandTrigger: 'hover',
                    checkStrictly: true,
                    emitPath: false,
                    value: 'teamId',
                    label: 'teamName',
                    children: 'childTeams',
                    disabled: 'isHide'
                },
                
                title: '提示',
                dialogVisible: false,
                officeLocationDialogVisible: false,
                confirmLoading: false,
                hasSelectedCity: false,
                officeLocationConfig: {
                    enableHeader: true,
                    enableFooter: true,
                    provinceCityIsShow: false,
                    arrowIsShow: false,
                },
                provinceCityConfig: {
                    enableHeader: true,
                    enableFooter: true,
                    provinceCityIsShow: false,
                    arrowIsShow: false,
                },
                selectedOfficeLocation: {
                    text: '全国',
                    id: '',
                    isSelect: true,
                },
                selectedCity: {
                    text: '全国',
                    id: '',
                    isSelect: true,
                },
                currentLocation: '全国',
                frequentLocation: 0,
                location: 0,
                currentMember: {},
                setType: 0,
                searchKey: '',
                listLocationName: '全国',
                adminCount: 0,
                memberCount: 0,
                memberList: [],
                listLoading: false,
                handOverList: [],
                enableHandOver: false,
                leaveDirect: false,
                teamId: '0',
                isAdminSearch: false,

                searchText: '',
            }
        },

        watch: {
            team(val) {
                // console.log(val);
                if(val && (val.teamId != undefined) && this.teamId != val.teamId) {
                    this.pager.current = 1;
                    // this.pager.size = 20;
                    this.pagerJump = 0;
                    this.isAdminSearch = false;
                    this.location = 0;
                    this.selectedCity.text = '全国';
                    this.searchKey = '';
                    this.teamId = val.teamId;
                    this.getMemberList();
                    this.scrollTop();
                }
            },
            teamList(val) {
                // console.log('team-----------------------list');
                // console.log(val);
                if(val[0].childTeams) {
                    this.teamListArr = val[0].childTeams;
                }
                // console.log(this.teamListArr);
            },
            // 监听城市选择弹窗出现，出现时去对应选中的城市值
            'provinceCityConfig.provinceCityIsShow': {
                handler(newV, oldV) {
                    // console.log("watch", newV, oldV);
                    if(newV) { // 城市筛选框出现的时候
                        // console.log(this.location);
                        // console.log(this.selectedCity);
                        // console.log(this.$refs.provinceCityConfig);
                        if(this.location) {
                            let _selectcities = [];
                            _selectcities.push({
                                // id: this.location,
                                id: this.selectedCity.id,
                                selected: true,
                                text: this.selectedCity.text,
                            });
                            this.$nextTick(() => {
                                this.$refs.provinceCity.selectedCity = _selectcities;
                            });
                        }
                    }
                },
                immediate: true,
                deep: true
            },
            'officeLocationConfig.provinceCityIsShow': {
                handler(newV, oldV) {
                    // console.log("watch", newV, oldV);
                    if(newV) { // 办公所在地城市选择框出现的时候
                        // console.log(this.hasSelectedCity);
                        if(this.hasSelectedCity) {
                            // console.log(this.frequentLocation);
                            // console.log(this.selectedOfficeLocation);
                            // console.log(this.$refs.officeLocationCity);
                            let _selectcities = [];
                            _selectcities.push({
                                // id: this.frequentLocation,
                                id: this.selectedOfficeLocation.id,
                                selected: true,
                                text: this.selectedOfficeLocation.text,
                            });
                            this.$nextTick(() => {
                                this.$refs.officeLocationCity.selectedCity = _selectcities;
                            });
                        }
                    }
                },
                immediate: true,
                deep: true
            },
        },

        computed: {
            verificationToken() {
                return this.$store.state.verificationToken.verificationToken;
            },
            user() {
                return this.$store.state.user.userInfo;
            },
            confirmBtnText() {
                if(this.setType == 4 && this.handOverList.length > 0) {
                    return '去安排'
                }else {
                    return '确定'
                }
            },
            computedHandOverList() {
                return this.handOverList.filter(it => (
                    it.realName.toLowerCase().includes(this.searchText.toLowerCase()) || 
                    it.nickname.toLowerCase().includes(this.searchText.toLowerCase()) || 
                    (it.realName + '@' + it.nickname).toLowerCase().includes(this.searchText.toLowerCase())
                    ));
            },
        },

        filters: {
            formatTitle(val) {
                let title = [];
                val.isAdministrator ? (title.push('管理员')) : '';
                val.isTeamLeader ? (title.push('Leader')) : '';
                // return title.length > 0 ? ('(' + title.join('、') + ')') : ''
                return title.length > 0 ? (title.join('、')) : ''
            }
        },

        mounted() {
            if(this.teamList.length > 0 && this.teamList[0].childTeams) {
                this.teamListArr = this.teamList[0].childTeams;
            }
            this.getMemberList();
        },

        methods: {
            dateFormatter(row, column, cellValue, index) {
                return cellValue.split(' ')[0];
            },
            getTeamList(teamList) {
                // console.log('jinlail---------')
                // console.log(teamList)
                let listArr = [];
                for (let team of teamList) {
                    // console.log(team);
                    // if(team.teamId === this.managingTeamId) {
                    //     console.log('team.teamId === this.managingTeamId');
                    //     continue;
                    // } else 
                    if(team.childTeams) {
                        if(team.childTeams.length > 0) {
                            team.childTeams = this.getTeamList(team.childTeams);
                        } else {
                            if(team.level > 1) {
                                delete team.childTeams;
                            }
                        }
                    }
                    listArr.push(team);
                }
                return listArr;
            },

            // 函数部分    
            // 节点点击事件
            handleNodeClick(data) {
                // 这里主要配置树形组件点击节点后，设置选择器的值；自己配置的数据，仅供参考
                this.chooseName = data.teamName;
                this.saveForm.chooseId = data.teamId;
                // 选择器执行完成后，使其失去焦点隐藏下拉框的效果
                this.$refs.selectUpResId.blur()
            },
            // 选择器配置可以清空选项，用户点击清空按钮时触发
            handleClear() {
                // 将选择器的值置空
                this.chooseName = ''
                this.saveForm.chooseId = ''
            },

            // 添加成员
            handleAddMember() {
                // console.log('点击添加成员');
                // console.log(this.$refs)
                this.$refs.addMemberDialog.show();
            },
            refreshMember(){
                // console.log('需要刷新列表-----------');
                let currentTeam = {
                    teamId: this.team.teamId,
                    teamName: this.team.teamName
                };
                this.$emit('refresh-member-list', currentTeam);
                // 刷新成员列表
                // this.getMemberList();
            },

            // composeValue(memberItem, type) {
            //     return {
            //         'member': memberItem,
            //         'type': type
            //     }
            // },
            // handleCommand(command) {
            //     console.log(command);
            //     // console.log(command.member);
            //     // this.$message('click on item ' + command.type);
            //     // command.type：0 设置管理员， 1 取消管理员， 2 设置Leader， 3 取消Leader, 4 离职，5 修改所属团队
            //     this.currentMember = command.member;
            //     this.setType = command.type;
            //     if(command.type == 4) {
            //         this.title = '提示';
            //         this.handleGetHandoverList(command.member);
            //     } else if(command.type == 5) {
            //         this.title = '修改所属团队';
            //         // this.managingTeamId = command.member.teamId;
            //         // teamId为0，没有团队
            //         // if(command.member.teamId != '0') {
            //             this.saveForm.chooseId = command.member.teamId;
            //             this.chooseName = command.member.teamName;
            //         // }
            //         // console.log('aa-------------------1')
            //         // console.log(this.teamListArr)
            //         // console.log(this.teamListArr.length)
            //         if(this.teamListArr.length){
            //             let list = {
            //                 teamList: this.teamListArr
            //             };
            //             list = JSON.parse(JSON.stringify(list));
            //             this.companyTeamList = this.getTeamList(list.teamList);
            //             // console.log(this.companyTeamList)
            //         }
            //         this.dialogVisible = true;
            //     } else {
            //         this.title = '提示';
            //         this.dialogVisible = true;
            //     }
            // },

            handleSetMember(member, type) {
                // console.log(member);
                // console.log(type);
                // type：0 设置管理员， 1 取消管理员， 2 设置Leader， 3 取消Leader, 4 离职，5 修改所属团队
                this.currentMember = member;
                this.setType = type;
                if(type == 4) {
                    this.title = '提示';
                    this.handleGetHandoverList(member);
                } else if(type == 5) {
                    this.title = '修改所属团队';
                    this.saveForm.chooseId = member.teamId;
                    this.chooseName = member.teamName;
                    if(this.teamListArr.length){
                        let list = {
                            teamList: this.teamListArr
                        };
                        list = JSON.parse(JSON.stringify(list));
                        this.companyTeamList = this.getTeamList(list.teamList);
                        // console.log(this.companyTeamList)
                    }
                    this.dialogVisible = true;
                } else if(type == 6) {
                    this.title = '修改办公所在地';
                    if(member.frequentLocation) {
                        this.frequentLocation = member.frequentLocationId;
                        this.selectedOfficeLocation = {
                            text: member.frequentLocation,
                            id: member.frequentLocationId,
                            isSelect: true,
                        };

                        this.hasSelectedCity = true;
                    } else {
                        this.frequentLocation = 0;
                        this.selectedOfficeLocation = {
                            text: '全国',
                            id: '',
                            isSelect: true,
                        };
                        // console.log(this.$refs.officeLocationCity);
                        if(this.$refs.officeLocationCity) {
                            // 清除办公所在地城市选择弹窗的选择
                            this.$refs.officeLocationCity.selectedCity = [];
                            this.$refs.officeLocationCity.currentIndex = 0;
                            this.$refs.officeLocationCity.selectedProvince = '';
                        }

                        this.hasSelectedCity = false;
                    }
                    
                    this.officeLocationDialogVisible = true;
                } else {
                    this.title = '提示';
                    this.dialogVisible = true;
                }
            },

            // 在底部时，点击翻页要定位到顶部
            scrollTop() {
                document.getElementsByClassName('member-page')[0].scrollTop = 0;
            },
            // handleCurrentChange(current) {
            //     this.scrollTop();
            //     this.pager.current = current;
            //     this.getMemberList();
            // },
            handleSizeChange(val){
                this.scrollTop();
                this.pager.size = val;
                this.pager.current = 1;
                this.getMemberList();
            },
            handleCurrentChange(current) {
                this.scrollTop();
                this.pager.current = current;
                this.getMemberList();
            },
            handlePagerJump(pagerJump) {
                pagerJump = Number.parseInt(pagerJump);
                this.pagerJump = pagerJump;
                if (
                    pagerJump > 0 &&
                    pagerJump <= Math.ceil(this.memberCount / this.pager.size)
                ) {
                    this.handleCurrentChange(pagerJump);
                }
            },

            handleGetMemberList() {
                this.pager.current = 1;
                this.getMemberList();
            },
            // 获取成员列表
            getMemberList() {
                this.listLoading = true;
                _request({
                    url: teamUrl.get_firm_teams_members,
                    method: 'POST',
                    baseURL: "LbdOpenApi",
                    data: {
                        start: (this.pager.current - 1) * this.pager.size,
                        take: this.pager.size,
                        teamId: this.teamId,
                        key: this.searchKey,
                        // location: this.location ? this.location : 0, // 成员基本信息的城市
                        frequentLocation: this.location ? this.location : 0, // 办公所在地
                    }
                }).then(res => {
                    this.memberCount = res.total;
                    this.memberList = res.list || [];
                    this.handleFormatLocation(res.list || []);
                }).finally(() => {
                    this.listLoading = false;
                })
            },

            // 选择城市查询
            handleSelectCity() {
                if (this.$refs.provinceCity.selectedCity[0]) {
                    this.selectedCity = this.$refs.provinceCity.selectedCity[0];
                } else {
                    this.selectedCity = {
                        text: '全国',
                        id: '',
                        isSelect: true,
                    };
                }
                this.currentLocation = this.selectedCity.text;
                this.location = this.selectedCity.id;

                this.pager.current = 1;
                this.$nextTick(() =>{
                    this.getMemberList();
                })

                return this.provinceCityConfig.provinceCityIsShow = false;
            },
            handleCitySelectCancel() {
                if (this.$refs.provinceCity.selectedCity[0] && this.$refs.provinceCity.selectedCity[0].id !== this.selectedCity.id) {
                    this.$refs.provinceCity.selectCity(this.selectedCity);
                }
                this.$refs.provinceCity.currentIndex = undefined;
                this.$refs.provinceCity.selectedProvince = '';
                return this.provinceCityConfig.provinceCityIsShow = false;
            },

            resetForm() {
                this.$refs.saveForm.resetFields();
            },
            // 点击弹窗取消按钮
            handleCancel() {
                this.dialogVisible = false;
                this.enableHandOver = false;
                if(this.setType == 5) {
                    this.resetForm();
                }
                this.chooseName = '';
                this.saveForm.chooseId = '';
                // this.managingTeamId = "";
            },
            // 点击弹窗确认按钮
            handleSetConfirm() {
                if(this.confirmLoading) {
                    return false;
                }

                // 修改所属团队提交
                if(this.setType == 5) {
                    this.migrateTeamMembers();
                    return false;
                }

                // 离职
                if(this.setType == 4 && this.enableHandOver) {
                    this.handleLeave();
                    return false;
                }
                if(this.setType == 4 && this.handOverList.length > 0) {
                    this.enableHandOver = true;
                    return false;
                }
                if(this.leaveDirect && this.setType == 4) {
                    this.handleLeave();
                    return  false;
                }

                const url = {
                    0: memberUrl.set_administrator,
                    1: memberUrl.set_administrator,
                    // 2: memberUrl.set_team_leader.replace('%p', this.teamId).replace('%p', this.currentMember.userId),
                    // 3: memberUrl.remove_team_leader.replace('%p', this.teamId).replace('%p', this.currentMember.userId)
                    2: teamUrl.add_team_leader,
                    3: teamUrl.cancel_team_leader,
                };
                let requestParams;
                // 设置管理员、取消管理员
                if(this.setType == 0 || this.setType == 1) {
                    requestParams = {
                        url: url[this.setType],
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        data: {
                            userId: this.currentMember.userId,
                            __RequestVerificationToken: this.verificationToken
                        }
                    }
                }
                // 设置Leader, 取消Leader
                else if(this.setType == 2 || this.setType == 3) {
                    requestParams = {
                        url: url[this.setType],
                        method: 'POST',
                        baseURL: "LbdOpenApi",
                        data: {
                            teamId: this.teamId,
                            userId: this.currentMember.userId
                        }
                    }
                } 

                this.confirmLoading = true;
                _request(
                    // {
                    //     url: url[this.setType],
                    //     method: 'POST',
                    //     headers: {
                    //         'Content-Type': 'application/x-www-form-urlencoded'
                    //     },
                    //     data: {
                    //         userId: this.currentMember.userId,
                    //         __RequestVerificationToken: this.verificationToken
                    //     }
                    // }
                    requestParams
                ).then(res => {
                    this.currentMember[MEMBER_SET[this.setType].key] = MEMBER_SET[this.setType].value;
                    if(this.setType == 0) {
                        this.adminCount += 1;
                    } else if(this.setType == 1) {
                        this.adminCount -= 1;
                    }
                    shortTips('操作成功！');
                }).finally(() => {
                    this.confirmLoading = false;
                    this.dialogVisible = false;
                })
            },

            // 修改所属团队
            migrateTeamMembers() {
                // if(!this.saveForm.chooseId) {
                //     this.$message.error('请选择所属团队');
                //     return;
                // }
                this.$refs.saveForm.validate((valid) => {
                    if (valid) {
                        this.confirmLoading = true;
                        _request(
                            {
                                url: teamUrl.migrate_team_members,
                                method: 'POST',
                                baseURL: "LbdOpenApi",
                                data: {
                                    toTeamId: this.saveForm.chooseId,
                                    userId: this.currentMember.userId
                                }
                            }
                        ).then(res => {
                            shortTips('操作成功！');
                            // 刷新列表
                            // this.$emit('member-leave');
                            let currentTeam = {
                                teamId: this.team.teamId,
                                teamName: this.team.teamName
                            };
                            this.$emit('refresh-member-list', currentTeam);
                        }).finally(() => {
                            this.confirmLoading = false;
                            this.dialogVisible = false;
                        })
                    }
                })
            },

            handleFormatLocation(list) {
                // let location = list && list[0] && list[0].location || '全国';
                let location = list && list[0] && list[0].location || this.currentLocation;

                this.adminCount = list && list[0] && list[0].adminCount || 0;

                for(let i = 1; i < list.length; i ++) {
                    if(list[i].location !== location) {
                        location = this.currentLocation || '全国';
                        break;
                    }
                }

                if(~location.indexOf(' ')) {
                    location = location.slice(location.indexOf(' ') + 1)
                }

                this.listLocationName = location;

                return ;
            },
            // 点击离职操作
            handleGetHandoverList(member) {
                this.handoverLoadind = this.$loading();
                // console.log(member)
                _request({
                    url: memberUrl.leave.replace('%p', member.userId),
                    method: 'POST'
                }).then(res => {
                    // console.log(res)
                    if(!res || res.length == 0) {
                        this.leaveDirect = true;
                    }else {
                        this.leaveDirect = false;
                    }
                    this.handOverList = res || [];
                    this.dialogVisible = true;
                    this.searchText = '';
                }).finally(() => {
                    this.handoverLoadind.close();
                })
            },
            // 离职
            handleLeave() {
                let member = null;

                for(let i = 0; i < this.handOverList.length; i++) {
                    if(this.handOverList[i].selected) {
                        member = this.handOverList[i];
                        break;
                    }
                }

                if(!member && !this.leaveDirect) {
                    shortTips('请选择交接人员');
                    this.confirmLoading = false;
                    return ;
                }

                this.confirmLoading = true;
                this.handoverLoadind = this.$loading();
                // console.log(this.currentMember);
                _request({
                    url: memberUrl.transfer,
                    method: 'POST',
                    data: {
                        fromId: this.currentMember.userId,
                        toId: !this.leaveDirect ? (member.userId || member.id) : null
                    }
                }).then(res => {
                    this.dialogVisible = false;
                    this.leaveDirect = false;
                    shortTips('操作成功');
                    // 刷新列表
                    // this.$emit('member-leave');
                    let currentTeam = {
                        teamId: this.team.teamId,
                        teamName: this.team.teamName
                    };
                    this.$emit('refresh-member-list', currentTeam);
                }).finally(() => {
                    this.confirmLoading = false;
                    this.handoverLoadind.close();
                })
            },
            handleSelectHandOver(member) {
                if(member.selected) {
                    this.$set(member, 'selected', false);
                }else {
                    this.$set(member, 'selected', true);
                }
                this.handOverList.forEach(item =>{
                    if(item !== member) {
                        this.$set(item, 'selected', false);
                    }
                })
            },
            handleSetEmptyTeam() {
                this.$emit('jump-create-team', this.team);
            },
            handleAdminSearch() {
                this.pager.current = 1;
                this.isAdminSearch = !this.isAdminSearch;
                this.getMemberList();
            },


            // 选择办公所在地
            handleSelectOfficeLocation() {
                // console.log(this.$refs.officeLocationCity);
                if (this.$refs.officeLocationCity.selectedCity[0]) {
                    this.selectedOfficeLocation = this.$refs.officeLocationCity.selectedCity[0];
                } else {
                    this.selectedOfficeLocation = {
                        text: '全国',
                        id: '',
                        isSelect: true,
                    };
                }
                this.frequentLocation = this.selectedOfficeLocation.id;

                if(this.$refs.officeLocationCity.selectedCity.length > 0) { // 有选中的城市
                    this.hasSelectedCity = true;
                } else {  // 没有选中的城市
                    this.hasSelectedCity = false;
                }

                return this.officeLocationConfig.provinceCityIsShow = false;
            },
            handleOfficeLocationSelectCancel() {
                if (this.$refs.officeLocationCity.selectedCity[0] && this.$refs.officeLocationCity.selectedCity[0].id !== this.selectedOfficeLocation.id) {
                    this.$refs.officeLocationCity.selectCity(this.selectedOfficeLocation);
                }
                this.$refs.officeLocationCity.currentIndex = undefined;
                this.$refs.officeLocationCity.selectedProvince = '';

                return this.officeLocationConfig.provinceCityIsShow = false;
            },
            // 移除选择的办公所在地
            removeSelectedOfficeLocation() {
                // console.log('移除-------------');
                this.frequentLocation = 0;
                this.selectedOfficeLocation = {
                    text: '全国',
                    id: '',
                    isSelect: true,
                };
                // 清除办公所在地城市选择弹窗的选择
                // console.log(this.$refs.officeLocationCity);
                if(this.$refs.officeLocationCity) {
                    this.$refs.officeLocationCity.selectedCity = [];
                    this.$refs.officeLocationCity.currentIndex = 0;
                    this.$refs.officeLocationCity.selectedProvince = '';
                }

                this.hasSelectedCity = false;
            },
            // 关闭办公所在地弹窗
            handleClose() {
                this.officeLocationDialogVisible = false;

                this.frequentLocation = 0;
                this.selectedOfficeLocation = {
                    text: '全国',
                    id: '',
                    isSelect: true,
                };
                // 清除办公所在地城市选择弹窗的选择
                // console.log(this.$refs.officeLocationCity);
                if(this.$refs.officeLocationCity) {
                    this.$refs.officeLocationCity.selectedCity = [];
                    this.$refs.officeLocationCity.currentIndex = 0;
                    this.$refs.officeLocationCity.selectedProvince = '';
                }

                this.hasSelectedCity = false;
            },
            // 修改办公所在地提交
            updateOfficeLoaction() {
                if(!this.frequentLocation) {
                    shortTips('请选择办公所在地');
                    return;
                }

                this.confirmLoading = true;
                _request(
                    {
                        url: teamUrl.update_office_location,
                        method: 'POST',
                        baseURL: "LbdOpenApi",
                        data: {
                            frequentLocation: this.frequentLocation,
                            userId: this.currentMember.userId
                        }
                    }
                ).then(res => {
                    shortTips('操作成功！');
                    this.officeLocationDialogVisible = false;

                    // 刷新列表
                    let currentTeam = {
                        teamId: this.team.teamId,
                        teamName: this.team.teamName
                    };
                    this.$emit('refresh-member-list', currentTeam);
                }).finally(() => {
                    this.confirmLoading = false;
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep .el-tree-node__expand-icon {
        font-size: 15px;
    }

    .el-tree {
        background: #fff;
        color: #333333;
        padding: 10px 0;
    }
    .el-tree ::v-deep .el-icon-caret-right:before {
        content: "";
        display: inline-block;
        background: url('~@src/assets/images/icon/icon-plus.png') no-repeat;
        background-size: 15px 15px;
        width: 15px;
        height: 15px;
    }
    .el-tree ::v-deep .el-tree-node__expand-icon.expanded.el-icon-caret-right:before {
        content: "";
        display: inline-block;
        background: url('~@src/assets/images/icon/icon-subtract.png') no-repeat;
        background-size: 15px 15px;
        width: 15px;
        height: 15px;
    }
    ::v-deep .el-tree-node__expand-icon.expanded {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    // 不可折叠的前面不再显示icon图标
    ::v-deep .is-leaf.el-icon-caret-right:before {
        background: none;
    }

    .el-tree ::v-deep .el-icon-caret-right:after {
        // content: '----';
        content: '';
        width: 18px;
        height: 20px;
        position: absolute;
        left: 25px;
        right: auto;
        top: 12px;
        border-width: 1px;
        border-top: 1px dashed #999999;
    }
    ::v-deep .el-tree-node__content span:nth-child(2) {
        padding-left: 20px;
    }

    .el-tree > ::v-deep .el-tree-node:after {
        border-top: none;
    }
    ::v-deep .el-tree-node {
        position: relative;
        padding-left: 16px;
    }
    //节点有间隙，隐藏掉展开按钮就好了,如果觉得空隙没事可以删掉
    ::v-deep .el-tree-node__expand-icon.is-leaf {
        // color: transparent;
        display: none;
    }
    ::v-deep .el-tree-node__children {
        padding-left: 16px;
    }
    // 竖线
    ::v-deep .el-tree-node {
        &::before {
            content: '';
            width: 1px;
            height: 100%;
            position: absolute;
            left: -4px;
            right: auto;
            // top: -26px;
            bottom: 0px;
            border-width: 1px;
            border-left: 1px dashed #999999;
        }
        

        .el-tree-node {
            &::before {
                left: -4px;
            }

            .el-tree-node {
                &::before {
                    left: 15px;
                }

                .el-tree-node {
                    &::before {
                        left: 33px;
                    }

                    .el-tree-node {
                        &::before {
                            left: 52px;
                        }

                        .el-tree-node {
                            &::before {
                                left: 70px;
                            }

                            .el-tree-node {
                                &::before {
                                    left: 89px;
                                }

                                .el-tree-node {
                                    &::before {
                                        left: 107px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    // 当前层最后一个节点的竖线高度固定
    // .el-tree-node :last-child:before {
    //     height: 52px;
    // }
    // 横线
    ::v-deep .el-tree-node {
        &::after {
            content: '';
            // width: 18px;
            width: 40px;
            // height: 20px;
            position: absolute;
            left: -4px;
            right: auto;
            top: 12px;
            border-width: 1px;
            border-top: 1px dashed #999999;
        }

        .el-tree-node {
            &::after {
                left: -4px;
            }

            .el-tree-node {
                &::after {
                    left: 15px;
                }

                .el-tree-node {
                    &::after {
                        left: 33px;
                    }

                    .el-tree-node {
                        &::after {
                            left: 52px;
                        }

                        .el-tree-node {
                            &::after {
                                left: 70px;
                            }

                            .el-tree-node {
                                &::after {
                                    left: 89px;
                                }

                                .el-tree-node {
                                    &::after {
                                        left: 107px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    // 去掉最顶层的虚线，放最下面样式才不会被上面的覆盖了
    .el-tree > ::v-deep .el-tree-node:before {
        border-left: none;
    }
    .el-tree > ::v-deep .el-tree-node:after {
        border-top: none;
    }

    // .el-tree-node__content>.el-tree-node__expand-icon {
    //     padding: 0;
    // }
</style>

<style lang="scss" scoped>
.member-wrap {
    .member-list {
        &-header {
            width: 100%;
            height: 56px;
            position: relative;

            .member-search-area {
                float: left;
                display: flex;
                align-items: center;
                justify-content: space-between;
                // width: 100%;
                width: 440px;
                height: 36px;
                outline: none;
                border: 1px solid #ddd;
                border-radius: 2px;

                .search-city-btn {
                    flex-shrink: 0;
                    position: relative;
                    text-align: center;
                    line-height: 36px;
                    cursor: pointer;

                    .search-city-content i {
                        padding: 0 10px;
                    }
                }

                /deep/ .el-input .el-input__inner {
                    border: 0 none;
                    height: 34px;
                    line-height: 34px;
                }
            }

            .add-member-btn{
                cursor: pointer;
                float: left;
                margin-left: 10px;
                width: 110px;
                height: 36px;
                line-height: 36px;
                border-radius: 4px;
                background-color: #38bc9c;
                color: #fff;
                text-align: center;
                font-size: 14px;
            }

            .member-search-btn {
                cursor: pointer;
                position: absolute;
                top: 0;
                left: 0;
                margin: auto;
                background: transparent;
                border-color: transparent;

                &:hover {
                    border-color: transparent;
                }

                &:active {
                    border-color: transparent;
                }
            }

            &-base {
                padding-right: 20px;
                float: right;
                height: 40px;
                line-height: 40px;
                border-radius: 2px 2px 0 0;
                // background: #F8F8F8;
                // margin-top: 20px;
                padding-left: 27px;
                color: #999999;

                .current-location {
                    // color: #38bc9c;
                    color: #333;
                    // padding: 0 40px 0 20px;
                    padding-right: 20px;
                }

                .is-admin {
                    // color: #FF7200;
                    color: #333;
                    cursor: pointer;
                }

                .member-count-center{
                    padding-right: 20px;
                    .member-count {
                        // padding-right: 20px;
                        color: #333;
                    }
                }
            }
        }
        &-content {
            .list-content-wrap {
                /deep/ .el-table {
                    color: #666;
                    .el-table__row td {
                        border-top: none;
                    }
                    th.is-leaf.el-table__cell {
                        background-color: #E8E8E8;
                        color: #666666;
                        border-right: 1px solid #CCCCCC;
                        padding-left: 16px;
                        padding-right: 16px;
                    }
                    // th.el-table_1_column_4.is-center.is-leaf.el-table__cell {
                    //     border-right: none;
                    // }
                    th.is-leaf.el-table__cell:nth-last-child(2) {
                        border-right: none;
                    }
                    tbody .el-table__cell {
                        border-right: 1px solid #ebeef5;
                        padding-left: 16px;
                        padding-right: 16px;
                    }
                    // td.el-table_1_column_4.is-center.el-table__cell {
                    //     min-width: 406px;
                    //     border-right: none;
                    // }
                    .el-table__row td.el-table__cell:last-child {
                        border-right: none;
                    }
                }
                .opr-dropdown-link{
                    cursor: pointer;
                    color: $primary;
                    .el-icon-arrow-down {
                        font-size: 12px;
                    }
                }

                .item-common {
                    display: inline-block;
                }
                .item-avatar {
                    width: 30px;
                    height: 30px;
                }
                .item-info {
                    margin-left: 20px;
                    font-size: 14px;
                    color: #333333;
                    .item-info-name {
                        display: inline-block;
                        // max-width: 200px;
                        max-width: 190px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        vertical-align: top;
                    }
                    .item-info-title {
                        padding: 2px 7px;
                        margin-left: 26px;
                        background-color: #EAF8F5;
                        border-radius: 8px;
                        color: #38BC9D;
                        font-size: 12px;
                    }
                }
                .item-option {
                    span {
                        cursor: pointer;
                        display: inline-block;
                        margin-right: 10px;
                        color: #38BC9D;
                        font-size: 14px;
                    }
                    span.line {
                        cursor: text;
                        color: #CCCCCC;
                    }
                }
            }

            /deep/ .el-pagination {
                margin: 30px 0 40px;
                padding: 0;
                font-weight: normal;
                text-align: right;
                
                // 给分页的当前页设置边框
                .btn-next, .btn-prev {
                    height: 28px;
                    border: 1px solid #DDDDDD;
                }
                .btn-prev {
                    border-right: none;
                    border-radius: 4px 0 0 4px;
                }
                .btn-next {
                    border-left: none;
                    border-radius: 0 4px 4px 0;
                }
                .el-pager {
                    border-top: 1px solid #DDDDDD;
                    border-bottom: 1px solid #DDDDDD;
                    li {
                        min-width: 26px;
                        height: 26px;
                        line-height: 26px;
                        font-size: 14px;
                    }
                }

                .el-pagination__total {
                    color: #666666;
                }
                .pagination-text{
                    color: #666666;
                    display: inline-block;
                    margin-left: 28px;
                    span{
                        height: 28px;
                        line-height: 28px;
                        font-size: 14px;
                        .el-input{
                            width: 48px;
                            margin: 0 5px;
                            &__inner{
                                height: 28px;
                                line-height: 28px;
                                padding: 0 5px;
                            }
                        }
                        &:nth-of-type(2){
                            margin-left: 5px;
                            // color: $primary;
                            cursor: pointer;
                        }
                    }
                }
                // .el-pagination__total,
                // .el-pagination__sizes,
                // .pagination-text {
                //     float: right;
                // }
            }

            .member-list-empty {
                margin-top: 125px;
                margin-left: 80px;

                .empty-item {
                    display: inline-block;
                    vertical-align: top;
                    line-height: 2;
                }

                .empty-img {
                    margin-right: 80px;
                    width: 240px;
                }

                .empty-tip {
                    padding-top: 20px;

                    &-btn {
                        margin-top: 20px;
                        width: 120px;
                        height: 36px;
                        line-height: 36px;
                        border-radius: 2px;
                        position: relative;
                        padding: 0;

                        .font-icon {
                            width: 10px;
                            height: 10px;
                            fill: #fff;
                            margin-top: -2px;
                        }
                    }
                }
            }
        }
    }

    .member-set {
        padding: 15px 20px 20px;
        margin-top: 40px;
        font-size: 16px;
        color: #444;
        text-align: center;

        span {
            color: #4cc2a6;
            padding: 0 3px;
        }

        &-tip {
            margin-top: 10px;
            font-size: 12px;
            color: #666;
            text-align: left;

            span {
                color: #ff7e5c;
            }
        }

        &-leave {
            text-align: center;

            p {
                font-size: 12px;
                color: #666;
                display: inline-block;
                text-align: left;

                span {
                    color: #FF7E5C;
                }
            }

            .member-set-leave-confirm {
                font-size: 16px;

                span {
                    color: #38bc9c;
                }
            }
        }

        .modify-owning-team {
            // .el-select {
            //     width: 86%;
            // }
            /deep/ .el-form-item__content {
                margin-left: 80px;
            }
            .el-select {
                width: 100%;
            }
        }
    }

    /deep/ .el-dialog__body {
        padding: 0;
    }

    .hand-over-content {
        .hand-over-tip {
            padding: 0 20px;
            background-color: #FEFAE0;
            font-size: 14px;
            color: #E24E3A;
            height: 40px;
            line-height: 40px;
        }
        .search-wrap{
            padding: 20px 60px;
            box-sizing: border-box;
        }

        .hand-over-list {
            height: 300px;
            overflow-y: auto;

            .hand-over-item-wrap {
                padding: 0 20px;
                cursor: pointer;

                &:hover {
                    background: #f8f8f8;
                }

                .hand-over-item {
                    display: inline-block;
                    vertical-align: top;
                    height: 68px;
                    line-height: 68px;
                }

                .hand-over-item-avatar {
                    // width: 12%;
                    margin-right: 20px;
                    img {
                        width: 50px;
                        border-radius: 100%;
                        border: 1px solid #979797;
                    }
                }

                .hand-over-item-info {
                    line-height: 1.4;
                    padding-top: 15px;
                    width: 80%;
                    position: relative;

                    .info-name {
                        font-size: 14px;
                        display: inline-block;
                        max-width: 268px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }

                    .info-description {
                        max-width: 268px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        .info-title {
                            font-size: 12px;
                            color: #999;
                        }
                    }

                    .tip-again {
                        position: absolute;
                        font-size: 14px;
                        color: #E24E3A;
                        right: 0;
                        top: 23px;
                    }
                }

                .hand-over-item-select {
                    // width: 5%;
                    margin-left: 20px;

                    .info-isSelect {
                        display: inline-block;
                        // width: 18px;
                        // height: 18px;
                        width: 16px;
                        height: 16px;
                        margin-top: 25px;
                        border: 1px solid #999;
                        border-radius: 50%;
                        // background: url('~@src/assets/images/icon/icon-checked.png') no-repeat;
                    }
                    .info-select {
                        border: none;
                        background-color: #38BC9D;
                        background-image: url('~@src/assets/images/project/checked.svg');
                        background-size: 16px 16px;
                    }
                }
            }
        }
    }

    // 为本页面搜索树设置滚动条
    .member-page .tree-node__children{
        max-height: 600px;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .office-location-dialog {
        .modify-office-location {
            margin: 20px 0;
            padding: 20px;
            .office-location-selected {
                display: inline-block;
                margin-left: 25px;

                .selected-option {
                    position: relative;
                    display: inline-block;
                    border: 1px solid #d3d3d3;
                    color: $primary;
                    padding: 0 23px 0 7px;
                    // vertical-align: top;
                    height: 26px;
                    line-height: 26px;

                    .option-remove {
                        position: absolute;
                        width: 23px;
                        right: 0;
                        top: 0;
                        text-align: center;
                        font-size: 10px;
                        color: #ccc;
                        cursor: pointer;
                    }
                }

                .default-selected {
                    display: inline-block;
                    border: 1px solid #d3d3d3;
                    color: $primary;
                    padding: 0 7px;
                    height: 26px;
                    line-height: 26px;
                }
            }
        }
    }
}
</style>

<style lang="scss">
.office-location-popover.el-popover {
    max-height: 600px;
    overflow-y: auto;
}
</style>
