var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "join-list" }, [
        _c("div", { staticClass: "join-list-header" }, [
          _vm._v("\n            有\n            "),
          _c("span", [_vm._v(_vm._s(_vm.joinList.length))]),
          _vm._v("\n            人申请加入，请尽快处理\n        "),
        ]),
        _c("div", { staticClass: "join-list-content" }, [
          _c(
            "ul",
            _vm._l(_vm.joinList, function (item) {
              return _c("li", { key: item.id }, [
                _c("div", { staticClass: "join-list-item item-avatar" }, [
                  _c("img", {
                    attrs: { src: _vm._f("avatar")(item.avatarUrl) },
                  }),
                ]),
                _c("div", { staticClass: "join-list-item item-info" }, [
                  _c(
                    "span",
                    {
                      staticClass: "item-info-name",
                      attrs: { title: item.realName + "@" + item.nickname },
                    },
                    [_vm._v(_vm._s(item.realName + "@" + item.nickname))]
                  ),
                  item.location
                    ? _c(
                        "span",
                        {
                          staticClass: "item-info-location",
                          attrs: { title: item.location },
                        },
                        [_vm._v("(" + _vm._s(item.location) + ")")]
                      )
                    : _vm._e(),
                  _c("br"),
                  _c("span", { staticClass: "info-text" }, [
                    _vm._v(_vm._s(item.message)),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "join-list-item item-operate" },
                  [
                    _c(
                      "el-dropdown",
                      { on: { command: _vm.handleCommand } },
                      [
                        _c(
                          "div",
                          [
                            _c("font-icon", {
                              staticClass: "item-icon",
                              attrs: { href: "#icon-ic_act_grey" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c(
                              "el-dropdown-item",
                              {
                                attrs: {
                                  command: _vm.composeValue(item, "accept"),
                                },
                              },
                              [_vm._v("同意")]
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                attrs: {
                                  command: _vm.composeValue(item, "refuse"),
                                },
                              },
                              [_vm._v("拒绝")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            }),
            0
          ),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.refuseDialogVisible,
            width: "450px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.refuseDialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "accept-confirm" }, [
            _c("div", [
              _c("p", [_vm._v("确定拒绝加入吗？")]),
              _c("p", [_vm._v("拒绝后将会自动从列表移除")]),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleCancelRefuse } }, [
                _vm._v("取消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.confirmLoading, type: "primary" },
                  on: { click: _vm.handleConfirm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "accept-dialog",
          attrs: {
            title: "确认同意加入",
            visible: _vm.acceptDialogVisible,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "620px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.acceptDialogVisible = $event
            },
            close: _vm.handleCancelAccept,
          },
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("p", { staticClass: "tip" }, [
                _vm._v("请选择 "),
                _c("span", { staticClass: "member-name" }, [
                  _vm._v(
                    _vm._s(_vm.member.realName) +
                      "@" +
                      _vm._s(_vm.member.nickname)
                  ),
                ]),
                _vm._v(" 的"),
              ]),
              _c(
                "el-form",
                {
                  ref: "acceptForm",
                  attrs: { model: _vm.acceptForm, "label-width": "120px" },
                },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "role-form-item", attrs: { label: "角色" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.baseRoleCode,
                            callback: function ($$v) {
                              _vm.baseRoleCode = $$v
                            },
                            expression: "baseRoleCode",
                          },
                        },
                        [
                          _vm._l(_vm.rolesArr, function (item) {
                            return _c(
                              "el-radio",
                              {
                                key: item.roleCode,
                                attrs: { label: item.roleCode },
                              },
                              [_vm._v(_vm._s(item.roleName))]
                            )
                          }),
                          _vm._l(_vm.rolesCFArr, function (item) {
                            return [
                              _vm.isCFuser
                                ? _c(
                                    "el-radio",
                                    {
                                      key: item.roleCode,
                                      attrs: { label: item.roleCode },
                                    },
                                    [_vm._v(_vm._s(item.roleName))]
                                  )
                                : _vm._e(),
                            ]
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "办公所在地" } },
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "bottom-start",
                            "popper-class": "office-location-popover",
                            width: "639",
                            "visible-arrow":
                              _vm.officeLocationConfig.arrowIsShow,
                            trigger: "click",
                          },
                          model: {
                            value: _vm.officeLocationConfig.provinceCityIsShow,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.officeLocationConfig,
                                "provinceCityIsShow",
                                $$v
                              )
                            },
                            expression:
                              "officeLocationConfig.provinceCityIsShow",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "office-location-selected",
                              attrs: { slot: "reference" },
                              slot: "reference",
                            },
                            [
                              _vm.hasSelectedCity
                                ? _c(
                                    "div",
                                    { staticClass: "selected-option" },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.selectedOfficeLocation.text
                                          )
                                        ),
                                      ]),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "option-remove",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.removeSelectedOfficeLocation.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-close",
                                          }),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              !_vm.hasSelectedCity
                                ? _c(
                                    "span",
                                    { staticClass: "default-selected" },
                                    [_vm._v("请选择")]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _c("province-city", {
                            ref: "officeLocationCity",
                            attrs: {
                              "grid-length": 7,
                              enableHeader:
                                _vm.officeLocationConfig.enableHeader,
                              enableFooter:
                                _vm.officeLocationConfig.enableFooter,
                            },
                            on: {
                              confirm: _vm.handleSelectOfficeLocation,
                              cancel: _vm.handleOfficeLocationSelectCancel,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.confirmLoading },
                  on: {
                    click: function ($event) {
                      _vm.acceptDialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.confirmLoading, type: "primary" },
                  on: { click: _vm.submit },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }